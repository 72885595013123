import React from 'react'
import { DateRangePicker, Range } from "react-date-range"
import moment from "moment";
import { useEffect, useState } from "react";
import dropdownIcon from '../../images/dropdownIcon.png'
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import { addDays } from 'date-fns';
import { enUS } from 'date-fns/locale'; 
import { Box, Typography } from '@mui/material';

export default function DateRangeFilter({handleChange, onChange,ranges,filterJSON,align,title, filters, tableData}) {
  // Array for Highlighting
  const ListOfUnverifiedDates = tableData?.map((tab) => tab?.unVerified_dates);
  
  let newDate = new Date()
  const customRanges = {
    'Last 7 days': {
      endDate: new Date(),
      startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
    },
    'Last 14 days': {
      endDate: new Date(),
      startDate: new Date(new Date().setDate(new Date().getDate() - 14)),
    },
    'Last 30 days': {
      endDate: new Date(),
      startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
    },
    'Last 3 months': {
      endDate: new Date(),
      startDate: new Date(new Date().setMonth(new Date().getMonth() - 3)),
    },
    'Last 12 months': {
      endDate: new Date(),
      startDate: new Date(new Date().setMonth(new Date().getMonth() - 12)),
    },
  };
  
  const [isOpen, setIsOpen] = useState(false);
  const [rangeLabel,setRangeLabel] = useState('Last 30 days')
  
  const [selectedRange, setSelectedRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),  //addDays(new Date(), 7),
      key: 'selection',
      range:null
    }
  ]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

 
  useEffect(() => {
    document.addEventListener("click", handlDropOpenClose);
    return () => {
        document.removeEventListener("click", handlDropOpenClose);
    }
    
},[])
 
const handlDropOpenClose = (event) => {
  if (((event?.target?.localName === "div") &&(event?.target?.dataset?.elementname !== filterJSON.apiKey)) || ((event?.target?.localName ==="input")&&(event?.target?.id !== filterJSON.apiKey))) {
      setIsOpen(false);
  }
}
 
  const changeDateRange = (value) => {
    onChange([value])
    setStartDate(moment(value.startDate).format("DD MMM YYYY"))
    setEndDate(moment(value.endDate).format("DD MMM YYYY"))
    setSelectedRange([value])
    const selectedRangelebel = Object.keys(customRanges).find(label => {
      const range = customRanges[label];
      return (
        range.startDate.getTime() === value.startDate.getTime() &&
        range.endDate.getTime() === value.endDate.getTime()
      );
    });
    setRangeLabel(selectedRangelebel)
    // setIsOpen(false)
    // handleChange(value.startDate, value.endDate, filterJSON.apiKey);
    //setIsOpen(false);
  };
 
  return (
    <div
   style = {{
  position: 'relative', // mb-3
  borderRadius: '2px', // rounded-[2px]
  padding: '2px', // p-[2px]
  backgroundColor: '#F8F8F8', // bg-[#F8F8F8]
}}
      
      // data-elementname={filterJSON.apiKey}
    >
      <div
      style={{
        // display: 'flex', // flex
        // justifyContent: 'space-between', // justify-between
        // paddingLeft: '0.5rem', // px-2 (horizontal padding: 0.5rem on both sides)
        // paddingRight: '0.5rem', // px-2 (horizontal padding: 0.5rem on both sides)
        // alignItems: 'center', // items-center
        // paddingTop: '0.5rem', // py-2 (vertical padding: 0.5rem on both top and bottom)
        // paddingBottom: '0.5rem', // py-2 (vertical padding: 0.5rem on both top and bottom)
        // width: '100%', // w-full
        // userSelect: 'none', // select-none
        // cursor: 'pointer', // cursor-pointer
        // color: '#707070', // text-[#707070]
      }}
        // className="flex justify-between px-2 items-center py-2 w-full select-none cursor-pointer text-[#707070]"
        onClick={() => setIsOpen(!isOpen)}
        // data-elementname={filterJSON.apiKey}
      >
        <p
         style={{display:'flex',backgroundColor:'#F0F0F0',alignItems:'center',width:'140px',justifyContent:'space-between',padding:'4px 8px'}} 
          // data-elementname={filterJSON.apiKey}
        >
          {rangeLabel ? rangeLabel : `${startDate} - ${endDate}`}
          <img
          src={dropdownIcon}
          // data-elementname={filterJSON.apiKey}
          style = {{
            transition: 'all 0.3s ease', // Assuming the default transition duration and easing function
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            width: '12px',
    height: '18px'
          }}
          // className={`${isOpen ? "rotate-180" : ""} transition`}
          alt="dropDownIcon"
        />
        </p>
      
      </div>
      {isOpen && (
        <div
          // data-elementname={filterJSON.apiKey}
          style = {{
            position: 'absolute', // absolute
            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06)', // shadow-lg
            top: '2.75rem', // top-11 (11 * 0.25rem)
            left: align == 'left' ? '0' : undefined, 
            right: align == 'right' ? '330px' : undefined, // left-0
            // right:'100vw',
            width:'200px',
            zIndex: 2, // z-[2]
            backgroundColor: 'white', // bg-white
          }}
          className="absolute shadow-lg top-11 left-0 z-[2] bg-white"
        >
          <Box >
          <DateRangePicker
          onChange={item => changeDateRange(item.selection)}
          moveRangeOnFirstSelection={false}
          months={1}
          showMonthAndYearPickers={true}
          ranges={ranges[filterJSON.apiKey]}
          direction="vertical"
          inputRanges={[]}
          locale={enUS} 
        
          // sx={{border:'1px solid'}}
          sx={{
            width:'100%'
            // '.rdrDefinedRangesWrapper': {
            //   // fontSize: '12px',
            //   width: '130px !important',
            //   // borderRight: '1px solid #eff2f7',
            //   // background: '#fff',
            // },
          }}
          renderStaticRangeLabel={({ range }) => range.label}
        staticRanges={Object.keys(customRanges).map(label => ({
        label,
        range: () => ({
          startDate: customRanges[label].startDate,
          endDate: customRanges[label].endDate,
        }),
        isSelected: range => {
          const definedRange = customRanges[label];
          return (
            range.startDate.getTime() === definedRange.startDate.getTime() &&
            range.endDate.getTime() === definedRange.endDate.getTime()
          );
        },
      }))}
        />
          </Box>
   
        </div>
      )}
    </div>
 
  );
};