import React, { useState, useEffect } from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Button,
  Paper,
  Stack,
  Box,
  Typography
} from '@mui/material';

const EditableAllDataGrid = ({ headers, jsonData, onUpdateData, editableColumns }) => {
  const [editing, setEditing] = useState(false);
  const orginaljson = JSON.parse(JSON.stringify(jsonData));
  const newData = [...orginaljson]
  const [editedData, setEditedData] = useState(newData);

  useEffect(() => {
    setEditedData(newData); 
  }, [jsonData]);

  const handleEditClick = () => {
    setEditedData(newData); 
    setEditing(true);
  };

  const handleSaveClick = () => {
      onUpdateData(editedData);
      setEditing(false);
  };

  const handleCancelClick = () => {
    setEditedData(orginaljson);
    setEditing(false);
  };

  const handleInputChange = (e, index, colIndex) => {
    const { name, value } = e.target;
    const updatedData = [...editedData];
    updatedData[index][name] = value;
    setEditedData(updatedData);

  };



  return (
    <Box sx={{ mb: 0, px: 6, pt: 3, pb: 0}}>
      <TableContainer component={Paper} sx={{ border: 'none' }}>
        <Table sx={{ border: 'none' }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#F9FAFC' }}>
              {headers.map((header) => (
                <TableCell key={header.name}>{header.lable}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {editedData.map((row, index) => (
              <TableRow key={index}>
                {headers.map((header, colIndex) => (
                  <TableCell key={colIndex}>
                    {!editing || !editableColumns.includes(colIndex) ? (
                      row[header.name.toLowerCase()]
                    ) : (
                      <TextField
                        name={header.name.toLowerCase()}
                        value={row[header.name.toLowerCase()]}
                        onChange={(e) => handleInputChange(e, index, colIndex)}
                        fullWidth
                        InputProps={{ disableUnderline: !editing, style: { border: 'none', outline: 'none' } }}
                      />
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={2}>
        {editing ? (
          <Stack direction="row" spacing={2}>
            <Button variant="contained" onClick={handleSaveClick}>
              Save
            </Button>
            <Button variant="outlined" onClick={handleCancelClick}>
              Cancel
            </Button>
          </Stack>
        ) : (
          <Button variant="contained" sx={{ boxShadow: 'none' }} onClick={handleEditClick}>
            Update Manually
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default EditableAllDataGrid;
