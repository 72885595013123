export const loginAction = (payload) => {
  return {
    type: "login",
    payload,
  };
};

export const userPermissions = (payload) => {
  return {
    type: "userpermissions",
    payload,
  };
};

export const setUsers = (payload) => {
  return {
    type: "setusers",
    payload,
  };
};

export const setNavOpen = (payload) => {
  return {
    type: "setNavOpen",
    payload,
  };
};

export const setSelectedUser = (payload) => {
  return {
    type: "setSelectedUser",
    payload,
  };
};

export const setTransporters = (payload) => {
  return {
    type: "settransporters",
    payload,
  };
};

export const setSelectedLeagues = (payload) => {
  return {
    type: "setSelectedLeagues",
    payload,
  };
};

export const setSelectedTeam = (payload) => {
  return {
    type: "setSelectedTeam",
    payload,
  };
};

export const setIsSystemLeague = (payload) => {
  return {
    type: "setIsSystemLeague",
    payload,
  };
};

export const setFeacthData = (payload) => {
  return {
    type: "setFeacthData",
    payload,
  };
};

export const setSelectedTransporter = (payload) => {
  return {
    type: "setSelectedTransporter",
    payload,
  };
};

export const setDeliveryHubs = (payload) => {
  return {
    type: "setDeliveryHubs",
    payload,
  };
};

export const setRoles = (payload) => {
  return {
    type: "setroles",
    payload,
  };
};

export const setZones = (payload) => {
  return {
    type: "setzones",
    payload,
  };
};

export const setDistricts = (payload) => {
  return {
    type: "setdistricts",
    payload,
  };
};

export const setLoading = (payload) => {
  return {
    type: "setloading",
    payload,
  };
};

export const setWaitinglistpot= (payload) => {
  return {
    type: "setWaitinglistpot",
    payload,
  };
};

export const setInstitutions = (payload) => {
  return {
    type: "setinstitutions",
    payload,
  };
};

export const setSelectedInstitutions = (payload) => {
  return {
    type: "setSelectedInstitutions",
    payload,
  };
};

export const setInstitutionsTypes = (payload) => {
  return {
    type: "setInstitutionsTypes",
    payload,
  };
};

export const setFormData = (payload) => {
  return {
    type: "setFormData",
    payload,
  };
};

export const setmatchweek = (payload) => {
  return {
    type: "setmatchweek",
    payload,
  };
};

export const setNotification = (payload) => {
  return {
    type: "setnotification",
    payload,
  };
};

export const setTripdetails = (payload) => {
  return {
    type: "setTripdetails",
    payload,
  };
};

export const setSelectedTripDetail = (payload) => {
  return {
    type: "setSelectedTripDetail",
    payload,
  };
};
export const setPotSettingList = (payload) => {
  return {
    type: "setPotSettingList",
    payload,
  };
};

export const setPotSetting = (payload) => {
  return {
    type: "setPotSetting",
    payload,
  };
};


export const setActiveMatchWeek = (payload) => {
  return {
    type: "setActiveMatchWeek",
    payload,
  };
};

