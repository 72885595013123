import { Box, Divider } from "@mui/material";
import { TransForm } from "../common/TransForm/Index";
import { PageTitle } from "../common/PageTitle";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { useCallback, useEffect } from "react";
import axios from "axios";
import { base_url } from '../backendurl/url'
import { useState } from "react";
import configFormInputs from "../common/jsons/formConfigs/configFormInputs";
import ModalPopUp from "../common/TransForm/ModalPopUp";
import { Notification } from "../common/Notification";
import { Config } from "../../utils/commonFuctions/Token";
import { RefreshToken } from "../../utils/commonFuctions/RefreshToken";

export const ConfigForm = ({ mode, title }) => {
  const { selectedUser } = useSelector(
    (state) => state
  );
  const [popup, setPopup] = useState(false)
  const { user_uuid } = useParams("user_uuid");
  const [loading, setLoading] = useState(false)
  const [potConfig, setPotConfig] = useState({})
  const [isupdate, setIsupdate] = useState(false)
  const [consfigData, setConfigdata] = useState()
  const [notification, setnotification] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const SubmitConfig = async () => {
    try{
      if (mode === "edit") {
        if (!isupdate) {
          await axios.post(`${base_url}/api/pot/potconfigs`, consfigData, Config)
        } else {
          await axios.put(`${base_url}/api/pot/potconfigs/${consfigData.id}`, consfigData, Config)
        }
      }
      setPopup(false)
      getPotConfig()
    }
    catch(error){
      // RefreshToken(error)
      RefreshToken(error,setErrorMessage,setnotification)  
    }
    }
   


  const onSubmit = async (data) => {
    setPotConfig(data)
    data.max_pot_value = typeof data.max_pot_value === 'string' ? parseFloat(data.max_pot_value.replace(/,/g, '')) : data.max_pot_value
    data.min_pot_value = typeof data.min_pot_value === 'string' ? parseFloat(data.min_pot_value.replace(/,/g, '')) : data.min_pot_value
    data.value_of_token = typeof data.value_of_token === 'string' ? parseFloat(data.value_of_token.replace(/,/g, '')) : data.value_of_token
    data.prediction_deadline = parseInt(data.prediction_deadline)
    data.adminfee = parseInt(data.adminfee)
    data.min_picks_for_splitting = parseInt(data.min_picks_for_splitting)
    data.tokens_per_pick = parseInt(data.tokens_per_pick)
    data.picks_per_player = parseInt(data.picks_per_player)
    data.pot_category = 'Default'
    data.pot_currency = data?.pot_currency == '' || data?.pot_currency == undefined ? "AED" : data?.pot_currency
    data.season = null
    delete data.Add
    data.default_pot = true
    if (data.max_pot_value < data.min_pot_value) {
      setErrorMessage('Min Pot Value Should not be Higher than Max Pot Value')
      setnotification(true)

    } else {
      setPopup(true)
      setnotification(false)
      setConfigdata(data)
    }

  };

  const getPotConfig = useCallback(async () => {
    try{
      if (!loading) {
        setLoading(true)
        const response = await axios.get(`${base_url}/api/pot/potconfigs?default=true`, Config);
        if (response?.data?.status == 'Success' && response.data.data.length) {
          setPotConfig(response.data.data[0]);
          setIsupdate(true)
        }
        if (response.data == []) {
          setIsupdate(false)
        }
        setLoading(false)
      }
    }
    catch(error){
      RefreshToken(error,setErrorMessage,setnotification)  
    }
   
  }, [selectedUser, user_uuid]);

  useEffect(() => {
    getPotConfig();
  }, []);

  return (
    <Box>
      <Box sx={{ px: 6, pt: 3 }} alignContent="center">
        <PageTitle pageTitle={'Configuring Round Parameters'} />
      </Box>
      <Divider sx={{ mb: 2, height: 4 }} />
      <TransForm
        submitAction={onSubmit}
        data={potConfig}
        mode={mode}
        cardlength={'twoCard'}
        formInputs={configFormInputs({ mode, tokendisable: isupdate })}
      />
      <ModalPopUp
        openpopup={popup}
        setPopup={setPopup}
        title={'Confirm the action ?'}
        subTitle={'Are you sure, you want to update a new pot with given details.'}
        onClick={SubmitConfig}
        save={'Yes, Update'}
        cancel={'Exit'}
      />
      <Notification message={errorMessage} setopen={setnotification} type={'error'} show={notification} />
    </Box>
  );
};
