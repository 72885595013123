import { TabContext, TabList, TabPanel } from "@mui/lab";
import { React, useState } from "react";
import _ from "lodash";
import { Box, Typography,Tab, Stack} from "@mui/material";
import { PageTitle } from "../common/PageTitle";
import { useLocation } from "react-router-dom";
import PotSetting from "../PotSetting/PotSetting";
import FixtureResults from "./FixtureResults";
import TeamsStanding from "./teamsStanding";

function LeagueDetails() {
  const [value, setValue] = useState("FixtureResults");
  const location = useLocation();
  let { state } = location;


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <Stack
        direction={"row"}
        sx={{ justifyContent: "space-between", px: 6, pt: 3 }}
        alignContent="center"
      >
        <PageTitle pageTitle={"Leagues"} />

        {/* <Button sx={{ backgroundColor: '#3C50E0', color: '#EFF4FB' }} onClick={gotoAddUser} >Add new league +</Button> */}
      </Stack>
      <Box sx={{ display: "flex", px: 6 }}>
        <Typography sx={{ pr: "3%" }}>
          {" "}
          <span style={{ color: "#64748B" }}>League ID:</span> {state?.league}
        </Typography>
        <Typography sx={{ pr: "3%" }}>
          {" "}
          <span style={{ color: "#64748B" }}>League Name:</span> {state?.leaguename}
        </Typography>
        <Typography sx={{ pr: "3%" }}>
          {" "}
          <span style={{ color: "#64748B" }}>Season:</span> {state?.season_year}
        </Typography>
      </Box>
      <TabContext value={value} sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            sx={{ px: 6, pt: 3 }}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="primary"
            aria-label="lab API tabs example"
          >
            <Tab
              label="Fixtures & Results"
              value="FixtureResults"
              sx={{
                mx: 3,
                fontSize: "16px",
                fontFamily: "Urbanist",
                color: value === "FixtureResults" ? "#3C50E0 !important" : "inherit",
              }}
            />
            <Tab
              label="Round Settings"
              value="PotSetting"
              sx={{
                mx: 3,
                fontSize: "16px",
                fontFamily: "Urbanist",
                color: value === "PotSetting" ? "#3C50E0 !important" : "inherit",
              }}
            />
            <Tab
              label="Standings"
              value="TeamsStanding"
              sx={{
                mx: 3,
                fontSize: "16px",
                fontFamily: "Urbanist",
                fontWeight: "600",
                color: value === "TeamsStanding" ? "#3C50E0 !important" : "inherit",
              }}
            />
          </TabList>
        </Box>
        <TabPanel value="FixtureResults" sx={{ width: "100%" }}>
          <FixtureResults season_id= {state?.id} season={state?.season_year}  league={state?.league}/>
        </TabPanel>
        <TabPanel value="PotSetting" sx={{ width: "100%" }}>
          <PotSetting league= {state?.id} season={state?.season_year} />
        </TabPanel>
        <TabPanel value="TeamsStanding" sx={{ width: "100%" }}>
          <TeamsStanding league= {state?.id} season={state?.season_year}/>
        </TabPanel>
      </TabContext>
    </Box>
  );
}

export default LeagueDetails;
