import {
  Box,
  Button,
  Stack,
  Typography,
  InputAdornment,
} from "@mui/material";
import {  useNavigate } from "react-router-dom";
import { loginAction } from "../../../redux/action";
import AuthWrapper from "../AuthWrapper";
import { useDispatch } from "react-redux";
import { TextField } from "@mui/material";
import { Formik, Form } from "formik";
import email_icon from "../../../images/email_icon.png";
import lock_icon from "../../../images/lock_icon.png";
import { useState } from "react";
import { base_url } from '../../backendurl/url'
import Axios from "axios";
import { useStyles } from "../PlaceHolderStyle";
import { FaEye, FaEyeSlash } from "react-icons/fa";


export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formdata, setFormData] = useState({
    email: "",
    password: ""
  })
  const[eyeOpen,seteyeOpen] = useState(false)
  const classes = useStyles();
  
  const [error, setError] = useState('')

  
  const handleChange = (e, setErrors) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    if (name == 'email') {
      if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
        setErrors({ [name]: '' })
      }
    } else {
      setErrors({ [name]: '' })
    }
 
  }
 
 
 
  const login = async () => {
    try {
      const response = await Axios.post(`${base_url}/api/accounts/token/`, formdata);
      if (response.status === 200 ) {
        if(response?.data?.isAdmin){
          // response?.data?.access && 
          // localStorage.setItem('token',response?.data?.access)
          // localStorage.setItem('userName', response?.data.username)
          // localStorage.setItem('isadmin', response?.data?.isAdmin)
          // localStorage.setItem('refresh', response?.data?.refresh)
          // localStorage.setItem('user_uuid',response?.data?.user_uuid)
          document.cookie = `token=${response?.data?.access || ""}; path=/; SameSite=None; Secure`;
          document.cookie = `refresh=${response?.data?.refresh || ""}; path=/; SameSite=None; Secure`;
          document.cookie = `userName=${response?.data?.username || ""}; path=/; SameSite=None; Secure`;
          document.cookie = `isadmin=${response?.data?.isAdmin || ""}; path=/; SameSite=None; Secure`;
          document.cookie = `user_uuid=${response?.data?.user_uuid || ""}; path=/; SameSite=None; Secure`;
          document.cookie = `is_superuser=${response?.data?.is_superuser}; path=/; SameSite=None; Secure`;
          dispatch(loginAction(response.data));
          navigate("/rounds", { replace: true });
        }
        else{
          setError("You Don't have authorized to access")
        }
      } 
      else {
        setError("Invalid credentials")
      }
    } catch (error) {
      setError("Invalid credentials")
    }
  };
 

  const validate = () => {
    const errors = {};
    if (formdata.email == '') {
      errors.email = 'Please enter a valid email address.';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formdata.email)) {
      errors.email = 'Invalid email address';
    }
 
    if (formdata.password == '') {
      errors.password = 'Please enter a Password.';
    }
    return errors;
  };
 


  return (
    <AuthWrapper title="Login" subtitle="Please login with your email">
      <Box sx={{ mt: 3, borderRadius: '12px',fontFamily : 'Inter !important'}}>
        {error != '' ? <p style={{ color: 'red' }}>{error}</p> : ''}
        <Formik
          initialValues={formdata}
          validate={validate}
          onSubmit={login}
        >
          {({ errors, touched, setErrors }) => (
            <Form >
              <Box sx={{ mt: 3, borderRadius: 19,fontFamily : 'Inter !important'  }}>
                <Stack direction="column" justifyContent="center">
                  <TextField
                    sx={{ mb: 2,fontFamily : 'serif !important'  }}
                    name="email"
                    placeholder="Email"
                    className ={ classes.root}
                    onChange={(e) => handleChange(e, setErrors)}
                    error={touched.email && errors.email}
                    helperText={touched.email && errors.email}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={email_icon} alt="icon" />
                          <Typography color="#111111" sx={{ ml: 2 }}>|</Typography>
                        </InputAdornment>
                      ),
                      disableUnderline: true,
                      sx: {
                        borderRadius: "12px",
                        backgroundColor: "#0000001A",
                        border: "none",
                        outline: "none",
                      },
                    }}
                  />
                  <TextField
                    sx={{ mb: 2 }}
                    name="password"
                    placeholder=" Password"
                    type={eyeOpen?'text':'password'}
                    className ={ classes.root}
                    onChange={(e) => handleChange(e, setErrors)}
                    error={touched.password && errors.password}
                    helperText={touched.password && errors.password}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={lock_icon} alt="icon" />
                          <Typography color="#111111" sx={{ ml: 2 }}>
                            |
                          </Typography>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment onClick={()=>seteyeOpen(!eyeOpen)} sx={{cursor: 'pointer'}} position="start">
                          {/* <img src={lock_icon} alt="icon" /> */}
                          <Typography color="#111111" sx={{ mr: 2 }}>
                            |
                          </Typography>
                        {eyeOpen ? <FaEye/> : <FaEyeSlash/>}
                        </InputAdornment>
                      ),
                      
                      disableUnderline: true,
                      sx: {
                        borderRadius: "12px",
                        backgroundColor: "#0000001A",
                        border: "none",
                        outline: "none",
                      },
                    }}
                  />
                </Stack>
                {/* <Stack direction="row" justifyContent="end">
                  <Button href="forgotpassword" sx={{ color: "#0085FF", boxShadow: 'none' }}>
                    Forgot Password?
                  </Button>
                </Stack> */}
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: "#0081F8" }}
                  sx={{ width: "350px", height: "40px", mt: 4, boxShadow: 'none' }}
                >
                  Login
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </AuthWrapper>
  );
}