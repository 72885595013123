export default function passWordChangeFormInputs(values) {
    return [
        {
            shape: "div",
            type: "wrapper",
            className: "",
            sx: {
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gridColumnGap: "50px",
                mt: 6,
            },
            children: [
                {
                    shape: "input",
                    type: "text",
                    name: "old_password",
                    // handleChange: "",
                    className: "border",
                    title: "Old Password",
                    label: "Old Password",
                    placeholder: "Enter your old password",
                    width: "100%",
                    validation: {
                        required:true,
                        minLength:8,
                        requiredMessage: "Old Password can't be empty",
                      },
                    
                },
                {
                    shape: "input",
                    type: "text",
                    name: "new_password",
                    // handleChange: "",
                    className: "border",
                    title: "New Password",
                    label: "New Password",
                    placeholder: "Enter your new password",
                    width: "100%",
                    validation: {
                        required:true,
                        minLength:8,
                        requiredMessage: "New Password can't be empty",
                      },
                    
                },
            ],
        },

        {
            shape: "div",
            type: "wrapper",
            className: "",
            sx: { display: "flex", justifyContents: "flex-start", mt: 6 },
            children: [
                {
                    shape: "button",
                    type: "action",
                    title:"Save Changes",
                    // submitButton : values.mode === "view" ? false : true ,
                    name: "Add",
                    validation: {
                        hiddenRules: [
                            {
                                mode: "view",
                            },
                        ],
                    },
                },
                {
                    shape: "button",
                    type: "cancel",
                    title: "Cancel",
                    variant: "outlined",
                    name: "cancel",
                    sx: {
                        mr: 2,
                    },
                },
            ],
        },

    ];
}
