
import { Navigate, useNavigate, useRoutes } from "react-router-dom";
import { UserForm } from "./components/Users/UserForm";
import UserList from "./components/Users/UserList";
import { ChangePassword } from "./components/Users/ChangePassword";
import DashboardLayout from "./layouts/dashboard";
import AuthLayout from "./layouts/auth";
import Login from './components/Auth/LoginPage.js/Login'
import ForgotPassword from "./components/Auth/ForgotPassword";
import GeneralLayout from "./pages/GeneralLayout";
import { useSelector } from "react-redux";
import _ from "lodash";
import Dashboard from "./components/Dashboard/Dashboard";
import VerifyEmail from "./components/Auth/VerifyEmail";
import VerifyOTP from "./components/Auth/VerifyOTP";
import { ConfigForm } from "./components/Configurations/ConfigForm";
import TeamsList from "./components/Configurations/TeamsList";
import { NewTeamForm } from "./components/Configurations/NewTeamForm";
import LeagueList from "./components/Configurations/Leagues/leaguesList";
import { LeagueForm } from "./components/Configurations/Leagues/leaguesForm";
import MatchList from "./components/Configurations/Match/MatchList";
import { MatchweekForm } from "./components/Configurations/Match/MatchForm";
import LeagueStatus from "./components/Leagues/leagueStatus";
import { LeaguesForm } from "./components/Leagues/leagueForm";
import Purchases from './components/Purchases/Purchases'
import { PotForm } from './components/PotSetting/PotForm'
import LeagueDetails from "./components/Leagues/LeagueDetails";
import Players from './components/Players/Players'
import AutoFeachFixtures from "./components/Leagues/AutoFetchFixtures";
import Pots from "./components/PotSetting/Pots";
import { WaitlistForm } from "./components/PotSetting/CreatePotForm";
import PotIdDetails from "./components/PotSetting/WaitListPots/PotIdDetails";
import ActivePotId from "./components/PotSetting/ActivePots/ActivePotId";
import ActiveMatchWeek from "./components/PotSetting/ActivePots/ActiveMatchWeek";
import CompletepotsId from "./components/PotSetting/CompletePots/CompletepotsId";
import CompleteMatchWeek from "./components/PotSetting/CompletePots/CompleteMatchWeek";
import CompleteWinnerDetails from "./components/PotSetting/CompletePots/CompleteWinnerDetails";
import { Manualfixtures } from "./components/Leagues/manualFixtures";
import PlayersDetails from "./components/Players/PlayerDetails";
import PlayersIdDetails from "./components/Players/PlayerIdDeatails";
import ChangePlayerDetails from "./components/Players/ChangePlayerDetails";
import Payouts from './components/Payouts/payouts'
import { useEffect, useState } from "react";
import CustomerQuery from "./components/CustomerQuery/CustomerQuery";

import { base_url } from "./components/backendurl/url";
import { useDispatch } from "react-redux";
import Axios from "axios";
import { userPermissions } from "./redux/action";
import { JackpotsDetails } from "./components/PotSetting/Jackpots/JackpotsDetails";
// ----------------------------------------------------------------------

export default function AppRouter() {
  const { userInfo } = useSelector((state) => state);
  const token = localStorage.getItem('token');
  const dispatch = useDispatch();

  const defaultPermissions = ['add_issuecategory',
    'change_issuecategory', 'delete_issuecategory', 'view_issuecategory',
    'add_otp', 'change_otp', 'delete_otp', 'view_otp',
    'add_profile', 'change_profile', 'delete_profile',
    'view_profile', 'add_tickets', 'change_tickets',
    'delete_tickets', 'view_tickets', 'add_user',
    'change_user', 'delete_user', 'view_user',
    'add_activepots', 'change_activepots', 'delete_activepots',
    'view_activepots', 'add_activepredictionshistory', 'change_activepredictionshistory',
    'delete_activepredictionshistory', 'view_activepredictionshistory', 'add_completedpots',
    'change_completedpots', 'delete_completedpots', 'view_completedpots',
    'add_configuredleagues', 'change_configuredleagues', 'delete_configuredleagues',
    'view_configuredleagues', 'add_jackpots', 'change_jackpots',
    'delete_jackpots', 'view_jackpots', 'add_leaguematchweek',
    'change_leaguematchweek', 'delete_leaguematchweek', 'view_leaguematchweek',
    'add_leagues', 'change_leagues', 'delete_leagues',
    'view_leagues', 'add_leagueseason', 'change_leagueseason',
    'delete_leagueseason', 'view_leagueseason', 'add_matches',
    'change_matches', 'delete_matches', 'view_matches',
    'add_matchweek', 'change_matchweek', 'delete_matchweek',
    'view_matchweek', 'add_paymentsreceipts', 'change_paymentsreceipts',
    'delete_paymentsreceipts', 'view_paymentsreceipts', 'add_payout',
    'change_payout', 'delete_payout', 'view_payout', 'add_payoutrequest',
    'change_payoutrequest', 'delete_payoutrequest', 'view_payoutrequest',
    'add_playerpots', 'change_playerpots', 'delete_playerpots',
    'view_playerpots', 'add_playerwinnings', 'change_playerwinnings',
    'delete_playerwinnings', 'view_playerwinnings', 'add_potconfig',
    'change_potconfig', 'delete_potconfig', 'view_potconfig',
    'add_potplayers', 'change_potplayers', 'delete_potplayers',
    'view_potplayers', 'add_pots', 'change_pots',
    'delete_pots', 'view_pots', 'add_predictionlog',
    'change_predictionlog', 'delete_predictionlog', 'view_predictionlog',
    'add_purchaserequest', 'change_purchaserequest', 'delete_purchaserequest',
    'view_purchaserequest', 'add_standings', 'change_standings',
    'delete_standings', 'view_standings', 'add_teams',
    'change_teams', 'delete_teams', 'view_teams',
    'add_waitlistpredictions', 'change_waitlistpredictions', 'delete_waitlistpredictions', 'view_waitlistpredictions']


  const [appAccess, setAppAccess] = useState({}); 

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(';').shift()
    };
    return null;
  };

  const configToken = () => {
    const token = getCookie("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return config
  }

  const getPermissions = async () => {
    const updatedAccess = {};
    const userpermissions = await Axios.get(`${base_url}/api/accounts/user/me`, configToken());
    // Set cookie for 7 days
    const cookieExpirationDays = 7;
    const expires = new Date(Date.now() + cookieExpirationDays * 24 * 60 * 60 * 1000).toUTCString();
    document.cookie = `menuData=${JSON.stringify(userpermissions.data.menu_permissions)}; expires=${expires}; path=/`;


    dispatch(userPermissions(userpermissions.data));
    userpermissions.data?.permissions.forEach((permission) => {
      if (defaultPermissions.includes(permission)) {
        updatedAccess[permission] = true;
      }
    });
    setAppAccess(updatedAccess);
  };

  useEffect(() => { 
    try {
      getPermissions();
    } catch (error) {
      console.error("Error in useEffect", error);
    }
  }, [userInfo]);

  return useRoutes([
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        {
          path: "",
          element:!_.isEmpty(getCookie('token'))? (
            <Navigate to="rounds" />
          ) : (
            <Navigate to="auth/login" />
          ),
        },
        {
          path: "dashboard",
          element: <Dashboard />,
        },
        {
          path: "rounds",
          element: <GeneralLayout title="pots" />,
          children: [
            { path: "", element: <Pots /> },
            { path: "add", element: <WaitlistForm mode="new" /> },
            { path: "edit/:id", element: <WaitlistForm mode="edit" /> },
            { path: "view/:id", element: <PotIdDetails mode="view" /> },
            { path: "activeround/view/:id", element: <ActivePotId mode="view" /> },
            { path: "activeround/matchweek/:id", element: <ActiveMatchWeek mode="view" /> },
            { path: "completeround/view/:id", element: <CompletepotsId mode="view" /> },
            { path: "completeround/matchweek/:id", element: <CompleteMatchWeek mode="view" /> },
            { path: "completeround/winner/:id", element: <CompleteWinnerDetails mode="view" /> },
            { path: "jackpots/details/:id", element: <JackpotsDetails mode="view" /> }, 

          ],
        },
        {
          path: "users",
          element: <GeneralLayout title="Users" />,
          children: [
            { path: "", element: <UserList /> },
            { path: "add", element: <UserForm mode="new" /> },
            { path: "edit/:user_uuid", element: <UserForm mode="edit" /> },
            { path: "view/:user_uuid", element: <UserForm mode="view" /> },
            { path: "changepassword/:user_uuid", element: <ChangePassword mode="changepassword" /> },
          ],
        },
        {
          path: "configurations",
          element: <GeneralLayout title="configurations" />,
          children: [{ path: "", element: <ConfigForm mode='view' /> },
          { path: "roundparameters", element: <ConfigForm mode='edit' /> },
          { path: "leagues", element: <LeagueList /> },
          { path: "leagues/add", element: <LeagueForm mode="new" /> },
          { path: "teams", element: <TeamsList /> },
          { path: "teams/add", element: <NewTeamForm mode="new" /> },
          { path: "matchweeks", element: <MatchList /> },
          { path: "matchweeks/add", element: <MatchweekForm mode="new" /> },
          ],
        },

        {
          path: "leagues",
          element: <GeneralLayout title="Leagues" />,
          children: [{ path: "", element: <LeagueStatus /> },
          { path: "add", element: <LeaguesForm mode="new" /> },
          { path: "add/autofixture", element: <AutoFeachFixtures /> },
          { path: "add/manualfixture", element: <Manualfixtures /> },
          { path: "details", element: <LeagueDetails mode="new" /> },
          { path: "details/add", element: <PotForm mode="new" /> },
          { path: "details/edit/:user_uuid", element: <PotForm mode="edit" /> }
          ],
        },

        {
          path: "purchases",
          element: <GeneralLayout title="Purchases" />,
          children: [{ path: "", element: <Purchases /> },
          ],
        },
        {
          path: "payouts",
          element: <GeneralLayout title="Payouts" />,
          children: [{ path: "", element: <Payouts /> },
          ],
        },
        {
          path: "players",
          element: <GeneralLayout title="players" />,
          children: [{ path: "", element: <Players /> },
          { path: "add", element: <ChangePlayerDetails mode="new" /> },
          { path: "edit/:player_uuid", element: <playerForm mode="edit" /> },
          { path: "view/:player_uuid", element: <playerForm mode="view" /> },
          { path: "player_details", element: <PlayersDetails mode="view" /> },
          { path: "player_details/player_id", element: <PlayersIdDetails mode="view" /> },
          ],
        },
        {
          path: "customer-query",
          element: <GeneralLayout title="customer-query" />,
          children: [{ path: "", element: <CustomerQuery /> },
            // { path: "add", element: <ChangePlayerDetails mode="new" /> },
            // { path: "edit/:player_uuid", element: <playerForm mode="edit" /> },
            // { path: "view/:player_uuid", element: <playerForm mode="view" /> },
            // { path: "player_details", element: <PlayersDetails mode="view" /> },
            // { path: "player_details/player_id", element: <PlayersIdDetails mode="view" /> },
          ],
        },
      ],
    },

    // {
    //   path: "*",
    //   element: !_.isEmpty(token) ? (
    //     <Navigate to="dashboard" />
    //   ) : (
    //     <Navigate to="auth/login" />
    //   ),
    // },

    // !_.isEmpty(token)
    //   ? {
    //     path: "auth/*",
    //     element: <Navigate to="/" replace />,
    //   }
    //   : {},
  ]);
}
