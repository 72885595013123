import { useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import TransTable from "../../common/TransTable";
import _ from "lodash";
import { Box, Button, IconButton, Switch, Typography } from "@mui/material";
import { PageTitle } from "../../common/PageTitle";
import { useDispatch } from "react-redux";
import { getAllUsers } from "../../../service";
import { Config } from "../../../utils/commonFuctions/Token";
import { utcTimetoLocalTimeWithzone } from '../../common/TransForm/Localdateformater';

import { setJackpots, setJackpotsDetails, } from "../../../redux/action";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Axios from "axios";
import { base_url } from "../../backendurl/url";
import { useState } from "react";
import UpdatePopup from "../../Leagues/UpdatePopup";
import { RefreshToken } from "../../../utils/commonFuctions/RefreshToken";
import { Notification } from "../../common/Notification";

export const JackpotsDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [notification, setnotification] = useState(false)
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')
    const dispatch = useDispatch();
    const token = localStorage.getItem("token");
    const {jackpotsdetails} = location.state
    const JackpotDetailsColumn = () => [
        {
            key: "pick_id",
            label: "Pick ID",
            align: "left",
            fontSize: "15px",
            valueRenderer: (data) => {
                return (
                    <Typography >{data['pick_id']}</Typography>
                );
            },
        },
        {
            key: "player_name",
            label: "Player Name",
            align: "left",
            fontSize: "15px",
            valueRenderer: (data) => {
                return (
                    <Typography >{data['player_name']}</Typography>
                );
            },
        },
        {
            key: "vote",
            label: "Vote",
            align: "left",
            fontSize: "15px",
            valueRenderer: (data) => {
                return (
                    <Typography >{data['vote'] === 'yes' ? 'Yes' : data['vote'] === 'no' ? 'No' : 'Not Voted'}</Typography>
                );
            },
        },

    ];


    // const getJackpotsDetails = useCallback(async () => {
    //     setLoading(true)
    //     dispatch(setJackpotsDetails([])); 
    //     try{
    //       const response = await Axios.get(`${base_url}/api/pot/jackpot`,Config);
    //       if (response?.status == 200) {
    //         dispatch(setJackpotsDetails(response?.data));
    //         setLoading(false)
    //       }
    //     }
    //     catch(error){
    //       RefreshToken(error,setErrorMessage,setnotification)
    //     }
    //   }, []);
  
    
    //   useEffect(() => {
    //     getJackpotsDetails();
    //   }, []);
    
    return (
        <>
            <Box sx={{ p: 2 }}>
                <PageTitle pageTitle={"Jackpots Details"} />
                <Box sx={{ display: "flex", px: 6 }}>
                    <Typography sx={{ pr: "3%" }}>
                        {" "}
                        <span style={{ color: "#64748B" }}>Jackpot ID:</span> {jackpotsdetails?.id} 
                    </Typography>
                </Box>
                <TransTable
                    showSearch={false}
                    checkbox={false}
                    columns={JackpotDetailsColumn()}
                    rows={jackpotsdetails?.voted}
                    isLoading={loading}
                />
            </Box>
        </>
    )
}
