import { InputAdornment } from "@mui/material";
import lockicon from '../../../../images/lockIcon.png'
export default function potcreateform(values) {
    return [
      {
        shape: "div",
        type: "wrapper",
        className: "",
        sx: {
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gridColumnGap: "30px",
        },
        children: [         
          {
            shape: "input",
            type: "text",
            name:"id",
            title: "Pot Category ID",
            label: "Round Category ID",
            priceDefaultValue: "100",
            placeholder: "Id",
            currencyDefaultValue: "AMD",
            isDisabled: true,
            inputAdornments:{
              end: values.tokendisable ?  <InputAdornment position="end"> <img src={lockicon} alt = 'lockicon'/></InputAdornment> : '',
            },
            mode: "edit",
            filterBasedOnField: "country",
            optionLabelKey: "name",
            width: '100%',            
          },          
          {
            shape: "input",
            type: "text",
            name:"pot_category",
            title: "Pot_Category",
            label: "Round Category",
            priceDefaultValue: "100",
            placeholder: "Select category",
            currencyDefaultValue: "AMD",
            isDisabled: values.type=='Launched' ? true : false,
            
            validation: {
                required: true,
                requiredMessage: "Round Category can't be empty",
              },
            // selectDisable: true,
            mode: "edit",
            // options : values?.PotSettingList,
            // customeonchange : values?.fillTheField,
            filterBasedOnField: "country",
            optionLabelKey: "name",
            width: '100%',   
            // validation: {
            //   required: true,
            //   requiredMessage: "Minimum pot value can't be empty",
            // },         
          },
          {
            shape: "input",
            type: "text",
            name: "tokens_per_pick",
            // handleChange: "",
            title: "no_of_tokens",
            isDisabled: values.allFieldDisable? values.allFieldDisable:values.type=='Launched' ? true : false,
            label: "No. of tokens per pick",
            className: "",
            placeholder: `Enter the value`,
            width: '100%',            
            validation: {
              required: true,
              requiredMessage: "No. of tokens per pick can't be empty",
            },
            // sx:{backgroundColor:'#0000001A', borderRadius:'12px', border:'none'},
            // inputAdornments:{
            //   end: <InputAdornment position="end"> % </InputAdornment>,
            // }
          }
        ],
      },
      {
        shape: "div",
        type: "wrapper",
        className: "",
        sx: {
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gridColumnGap: "30px",
          mt: 3,
        },
        children: [
          {
            shape: "input",
            type: "currencyinput",
            name:"min_pot_value",
            title: "mix pot value",
            label: "Minimum Round value",
            priceDefaultValue: "100",
            placeholder: "Enter the value",
            currencyDefaultValue: "AMD",
            isDisabled: values.allFieldDisable? values.allFieldDisable:values.type=='Launched' ? true : false,

            selectDisable: values.mode == 'edit' ? true :   values.allFieldDisable,
            mode: "edit",
            validation: {
                required: true,
                requiredMessage: "Minimum pot value can't be empty",
              },
            filterBasedOnField: "country",
            optionLabelKey: "name",
            width: '100%',            
          },
          {
            shape: "input",
            type: "currencyinput",
            name:"max_pot_value",
            title: "Maximum pot value",
            label: "Maximum Round value",
            isDisabled: values.allFieldDisable? values.allFieldDisable:values.type=='Launched' ? true : false,

            selectDisable: values.mode == 'edit' ? true :   values.allFieldDisable,
            priceDefaultValue: "100",
            placeholder: "Enter the value",
            currencyDefaultValue: "AMD",
            validation: {
              required: true,
              requiredMessage: "Minimum pot value can't be empty",
            },
            // isDisabled: values.tokendisable,
            // inputAdornments:{
            //   end: values.tokendisable ?  <InputAdornment position="end"> <img src={lockicon} alt = 'lockicon'/></InputAdornment> : '',
            // },
            mode: "edit",
            filterBasedOnField: "country",
            optionLabelKey: "name",
            width: '100%',            
          },
          {
            shape: "input",
            type: "text",
            name:"picks_per_player",
            title: "no_of_player *",
            label: "No. of picks per player",
            priceDefaultValue: "100",
            placeholder: "Enter the value",
            currencyDefaultValue: "AMD",
            isDisabled: values.allFieldDisable? values.allFieldDisable:values.type=='Launched' ? true : false,

            selectDisable: true,
            validation: {
                required: true,
                requiredMessage: "No. of picks per player can't be empty",
              },
            mode: "edit",
            filterBasedOnField: "country",
            optionLabelKey: "name",
            width: '100%',            
          }
          
        ],
      },
      {
        shape: "div",
        type: "wrapper",
        className: "",
        sx: {
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gridColumnGap: "30px",
          mt: 3,
        },
        children: [         
          {
            shape: "input",
            type: "number",
            name: "min_picks_for_splitting",
            // handleChange: "",
            title: "Minimum players to split the pot ",
            label: "Minimum players to split the pot ",
            className: "",
            placeholder: `Enter number of players`,
            isDisabled: values.allFieldDisable? values.allFieldDisable:values.type=='Launched' ? true : false,

            width: '100%',            
            validation: {
              required: true,
              requiredMessage: "Minimum players to split the pot * can't be empty",
            },
            // '(Hours before match of match week)'/
           
          },          
          {
            shape: "input",
            type: "number",
            name: "adminfee",
            // handleChange: "",
            title: "Admin fee ",
            label: "Admin fee ",
            className: "",
            placeholder: `Enter the value`,
            isDisabled: values.allFieldDisable? values.allFieldDisable:values.type=='Launched' ? true : false,

            width: '100%',            
            validation: {
              required: true,
              requiredMessage: "Admin fee can't be empty",
            },
            //  sx:{backgroundColor:'#0000001A', borderRadius:'12px', border:'none'},
            inputAdornments:{
              end: <InputAdornment position="end"> % </InputAdornment>,
            }
            
          },
          {
            shape: "input",
            type: "number",
            name: "prediction_deadline",
            // handleChange: "",
            title: "Deadline for prediction ",
            label: "Deadline for prediction  ",
            className: "",
            placeholder: `Enter number of hours`,
            width: '100%',            
            validation: {
              required: true,
              requiredMessage: "Deadline for prediction * can't be empty",
            },
            isDisabled: values.allFieldDisable? values.allFieldDisable:values.type=='Launched' ? false : false,

          }
        ],
      },
      {
        shape: "div",
        type: "wrapper",
        className: "",
        sx: {
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gridColumnGap: "30px",
          mt: 3,
        },
        children: [         
          {
            shape: "input",
            type: "number",
            name: "dead_line_voting",
            // handleChange: "",
            title: "No.of Deadline Voting",
            label: "No.of Deadline Voting",
            className: "",
            placeholder: `No.of Deadline Votings`,
            isDisabled: values.allFieldDisable? values.allFieldDisable:values.type=='Launched' ? false : false,

            width: '100%',            
            validation: {
              required: true,
              requiredMessage: "No.of Deadline Voting * can't be empty",
            },
            // '(Hours before match of match week)'/
           
          },             
        ],
      },
      {
        shape: "div",
        type: "wrapper",
        className: "",
        sx: {
          display: "grid",
          gridTemplateColumns: "repeat(1, 1fr)",
          gridColumnGap: "100px",
          mt: 1,
        },
        children: [         
            {
                shape: "input",
                type: "textArea",
                name: "description",
                // handleChange: "",
                title: "Description",
                label: "Description",
                className: "",
                placeholder: `Enter number of playersEnter description details`,
                width: '100%',     
                isDisabled: values.allFieldDisable? values.allFieldDisable:values.type=='Launched' ? true : false,
 
                // validation: {
                //   required: true,
                //   requiredMessage: "Deadline for prediction * can't be empty",
                // },
          },          
        ],
      },
      {
        shape: "div",
        type: "wrapper",
        className: "",
        sx: { display: "flex", justifyContents: "flex-start", mt: 3 },
        children: [
          {
            shape: "button",
            type: "action",
            title: values.mode === "view" ? "Edit" : "Save",
            submitButton : values.isupdate,
            name: "Saved",
            sx : {mr:2,
              backgroundColor : '#3C50E0',
              px:4,
            borderRadius: 1,
            border : '1px solid #3C50E0',
            '&:hover': {
              backgroundColor: '#3C50E0',
              border : '1px solid #3C50E0' // Remove background color change on hover
            },},
            validation: {
              hiddenRules: [
                {
                  mode: "view",
                },
              ],
            },
          },
          {
            shape: "button",
            type: "action",
            title: values.mode === "view" ? "Edit" : "Launch",
            submitButton : values.isupdate,
            name: "Launched",
            sx : {mr:2,
              backgroundColor : '#F1F5F9',
              color : '#1C2434',
              px:4,
            borderRadius: 1,
            border : '1px solid #F1F5F9',
            '&:hover': {
              backgroundColor: '#F1F5F9',
              border : '1px solid #F1F5F9' // Remove background color change on hover
            },},
            validation: {
              hiddenRules: [
                {
                  mode: "view",
                },
              ],
            },
          },
        {
          shape: "button",
          type: "cancel",
          title: "Cancel",
          // variant: "outlined",
          name: "cancel",
          sx: {
            mr: 2,
            color: '#000',
            px: 4,
            borderRadius: 1,
            backgroundColor : '#fff',
            border : '1px solid #E2E8F0',
            '&:hover': {
              backgroundColor: '#fff',
              border : '1px solid #E2E8F0' // Remove background color change on hover
            },
          },
        },
       
      ],
      },
      // {
  
      //     shape:"select",
      //     type: "select",
      //     label: "Role",
      //     name: "role",
      //     className:"border",
      //     validation: {
      //         required:true
      //     },
      //     options: [
      //         {
      //             label: "Admin",
      //             value: "admin"
      //         },
      //         {
      //             label: "User",
      //             value: "user"
      //         }
      //     ]
      // },
      // {
      //     type: "number",
      //     shape: "input",
      //     label: "Phone Number",
      //     name: "mobile",
      //     className: "border",
      //     title:"mobile",
      //     validation: {
      //         required: true,
      //         minLength: 10,
      //         maxLength:10
      //     }
      // },
    ];
  }
  