import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import pnplogo from '../../images/pnplogo.png'
import collapseLogo from '../../images/collapseLogo.png'
// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ iscollapse, sx }) {
  const logo = (
    <Box sx={{ width: "100%", height: 40, ...sx }} className="pl-5">
      {iscollapse ? <img src={collapseLogo} alt='logo' /> : <img style={{width:'150px'}} src={pnplogo} alt='logo' />}
    </Box>
  );


  return <RouterLink to="/">{logo}</RouterLink>;
}
