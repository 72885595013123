import PropTypes from "prop-types";
// material
import * as React from 'react';

import { alpha, styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, IconButton, Typography } from "@mui/material";
// components
import Iconify from "../../components/common/Iconify";
//
import AccountPopover from "./AccountPopover";

import { useSelector } from "react-redux";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
// ----------------------------------------------------------------------

const APPBAR_MOBILE = 70;
const APPBAR_DESKTOP = 70;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.primary.main, 1),
  position:"sticky !important",
  top:0
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
    backgroundColor: '#FFFFFF',
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {

  const userInfo = useSelector((state) => state.userInfo);
  let breadcrubs = window.location.pathname.split('/').map((item) => {
    return <Typography key="3" color="text.primary" sx={{ textTransform: 'capitalize' }}>
      {item}
    </Typography>
  })

  return (
    <RootStyle>
      <ToolbarStyle>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ color: 'black' }}
        >
          {breadcrubs}
        </Breadcrumbs>
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
          sx={{mr:'30px'}}
        >
          <AccountPopover userInfo={userInfo} />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
