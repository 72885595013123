import { Box, Divider, Typography } from "@mui/material";
import { TransForm } from "../common/TransForm/Index";
import { PageTitle } from "../common/PageTitle";
import { useDispatch, useSelector } from "react-redux";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { setNotification, setPotSettingList, setSelectedUser, setPotSetting } from "../../redux/action";
import { useCallback, useEffect } from "react";
import axios from "axios";
import { base_url } from '../backendurl/url'
import { useState } from "react";
import ModalPopUp from "../common/TransForm/ModalPopUp";
import potcreateform from "../common/jsons/formConfigs/potcreateform";
import { Notification } from "../common/Notification";
import { Config } from "../../utils/commonFuctions/Token";
import { RefreshToken } from "../../utils/commonFuctions/RefreshToken";

export const PotForm = ({ mode, title }) => {
  const { PotSetting } = useSelector(
    (state) => state
  );
  const [popup, setPopup] = useState(false)
  const [notification, setnotification] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  
  const location = useLocation()
  const { league, season ,type,pot_id} = location.state

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user_uuid } = useParams("user_uuid");
  const [loading, setLoading] = useState(false)
  const [message,setmessage] = useState('')
  const [potConfig, setPotConfig] = useState({})
  const [isupdate, setIsupdate] = useState(false)
  const [configData, setConfigdata] = useState()
  const [error, seterror] = useState('')
  const { PotSettingList } = useSelector((state) => state);
  const [allFieldDisable, setFieldDisable] = useState(false)
  const [defaultpot,setdefaultpot] = useState({})
  const [potCategoryList, setPotCategoryList] = useState()
  const [saveBtnText, setSaveBtnText] = useState({
    title: 'Do you want to save new pot category ?',
    content: 'Are you sure, you want to create & save a new pot category with given details.',
    btnText: 'yes , Save'
  })
  const SubmitConfig = async () => {
    try{
      configData.default_pot = false
      if (user_uuid) {
        await axios.put(`${base_url}/api/pot/potconfigs/${user_uuid}`, configData, Config,)
      } else {
        await axios.post(`${base_url}/api/pot/potconfigs`, configData, Config)
      }
      navigate(-1)
  
      setPopup(false)
      getPotConfig()
    }
  catch(error){
    RefreshToken(error,setErrorMessage,setnotification)
  }
  }



  const onSubmit = async (data, name) => {
    const response = await axios.get(`${base_url}/api/pot/potconfigs`, Config);

    const exist = response.data.data.find(item=> item.season==league && item.pot_category == data.pot_category)
    if(!user_uuid && exist){

      setnotification(true)
      setErrorMessage('Round Category should be unique')
      return
    }
    name == 'Saved' ? setSaveBtnText({
      title: 'Do you want to save new pot category ?',
      content: 'Are you sure, you want to create & save a new pot category with given details.',
      btnText: 'yes , Save'
    }) : setSaveBtnText({
      title: 'Do you want to launch this new pot category ?',
      content: 'Are you sure, you want to save & launch a new pot category with given details.',
      btnText: 'yes , Launch'
    })
    data.max_pot_value = typeof data.max_pot_value === 'string' ? parseFloat(data.max_pot_value.replace(/,/g, '')) : data.max_pot_value
    data.min_pot_value = typeof data.min_pot_value === 'string' ? parseFloat(data.min_pot_value.replace(/,/g, '')) : data.min_pot_value
    data.value_of_token = typeof data.value_of_token === 'string' ? parseFloat(data.value_of_token.replace(/,/g, '')) : data.value_of_token
    data.prediction_deadline = parseInt(data.prediction_deadline)
    data.dead_line_voting = parseInt(data.dead_line_voting)
    data.adminfee = parseInt(data.adminfee)
    data.min_picks_for_splitting = parseInt(data.min_picks_for_splitting)
    data.season = league
    data.status = name
    delete data.Submit
    if (data.max_pot_value < data.min_pot_value) {
      setnotification(true)
      setErrorMessage('Min Pot Value Should not be Higher than Max Pot Value')
    } else {
      setPopup(true)
      setnotification(false)
      setConfigdata(data)
    }
  };

  const getPotConfig = useCallback(async () => {
    seterror('')
    if (!loading) {
      setLoading(true)
      setLoading(false)
    }
  }, [PotSetting, user_uuid]);



  const getConfig = async () => {
    try{
      if (mode == 'new') {
        const response = await axios.get(`${base_url}/api/pot/potconfigs?default=true`, Config);
        if (response?.data?.data?.length) {
          delete response?.data?.data[0].id
          setdefaultpot(response?.data?.data[0])
          // dispatch(setPotSetting(response?.data?.data[0]))
        }
      }
      else{
        const response = await axios.get(`${base_url}/api/pot/potconfigs/${pot_id}`, Config);

        if (response?.status==200) {
          setdefaultpot(response.data)
        }
      }
    }
   catch(error){
    RefreshToken(error,setErrorMessage,setnotification)
   }
  }


  useEffect(() => {
    getConfig();
  }, []);

  return (
    <Box>
      <Box sx={{ px: 6, pt: 3 }} alignContent="center">
        <PageTitle pageTitle={'Create New Round Category'} />
      </Box>
      <Divider sx={{ mb: 2, height: 4 }} />
      <TransForm
        submitAction={onSubmit}
        data={defaultpot}
        mode={mode}
        cardlength={'threeCard'}
        formInputs={potcreateform({ mode, tokendisable: isupdate, allFieldDisable,type })}
      />
      <ModalPopUp
        openpopup={popup}
        setPopup={setPopup}
        title={saveBtnText.title}
        subTitle={saveBtnText.content}
        onClick={SubmitConfig}
        save={saveBtnText.btnText}
        cancel={'Exit'}
      />
      <Notification message={errorMessage} setopen={setnotification} type={'error'} show={notification} />
    </Box>
  );
};
