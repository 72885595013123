import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import { Box, Button, Typography, Divider, Stack, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import { Dateformater } from '../common/TransForm/dateFormater';
import { utcTimetoLocalTimeWithzone } from '../common/TransForm/Localdateformater';
import { TextareaAutosize } from '@mui/material';
import { PnpSelect } from '../common/TransForm/PnpSelect';

export default function CustomePopUp({ title,data, save, cancel, openpopup, setPopup, onClick }) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 665,
  
    height: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
  };
 
  const [formData,setFormData] = useState({
    status:data.status ? data.status:'',
    comments:data.comments ? data.comments:''
  })
  const [formErrors, setFormErrors] = useState({
    status:'',
    comments:''
  });
  const validateForm = () => {
    let errors = {};
    let isValid = true;
    if(formData.status ==''){
      errors.status = 'Please select a match status';
            isValid = false;
    }
    if(formData.comments ==''){
      errors.comments = 'Please select a match status';
            isValid = false;
    }

// if(type=='update_results'){    if (formData.matchStatus == '' || formData.matchStatus == undefined || formData.matchStatus === 'Select') {
//       errors.matchStatus = 'Please select a match status';
//       isValid = false;
//     }

//     if (formData.matchResult == '' || formData.matchResult == undefined || formData.matchResult === 'Select') {
//       errors.matchResult = 'Please select a match result';
//       isValid = false;
//     }

//     if (formData.hometeam_goal == '' || formData.hometeam_goal == undefined) {
//       errors.hometeam_goal = 'Home team goal cannot be empty';
//       isValid = false;
//     }

//     if (formData.awayteam_goal == '' || formData.awayteam_goal == undefined) {
//       errors.awayteam_goal = 'Away team goal cannot be empty';
//       isValid = false;
//     }}else{
//       if (formData.start_date == '') {
//         errors.matchResult = 'Please select a match date';
//         isValid = false;
//       }
//       if (formData.start_time == '') {
//         errors.matchResult = 'Please select a match time';
//         isValid = false;
//       }
//     }

    setFormErrors(errors);
    return isValid;
  };

  const handleSave = () => {
    if (validateForm()) {
      onClick(formData);
      setPopup(false);
    }
  };


  const handleChange = (key,value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: value,
    }));
  };
const inputs = [
    {label : 'Inquiry Status', value :data.status},
    {label : 'Inquiry ID', value : data.id},
    {label : 'Inquiry Type', value : data.issue_type},
    {label : 'Email', value : data.email},
    {label : 'Inquiry Description', value : data.issue},
]

  return (
    <Modal
      open={openpopup}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>

        <Typography variant="h6" component="h2" sx={{ px: 6, mt: 2 }}>
          {title}
        </Typography>
      
        
        <Box sx={{ mt: 2, px: 6 }}>

{inputs.map(({label,value})=>{
   return <Box sx={{py:2,display:'flex',alignItems:'center'}}>
            <Typography component="span" sx={{marginLeft: '4px',flexBasis:'30%'}}> {label}</Typography>        
           {label=='Inquiry Status'? <>: <PnpSelect onChange={(key,value)=>handleChange(key,value)} 
           customsx={{width:'160px',borderRadius:'10px',marginLeft:'10px',paddingRight:'12px',
            "& .MuiOutlinedInput-root": {
             borderRadius:'10px',
             backgroundColor:formData.status=='Open'?'#DD8A0D33':'#21965314',
             border:'red'
            },
            "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
              height: '80px',
              color :formData.status=='Open'?'#DD8A0D':'#219653',
            },
            "& .MuiOutlinedInput-notchedOutline":{
              border :formData.status=='Open'?'2px solid #DD8A0D33':'2px solid #21965314'
            }

          }} name={'status'} value={formData.status} options={[{'id':'Open','name':'Open'},{'id':'Not_Issue','name':'Not Issue'},{'id':'Closed','name':'Resolved'}]}/></> :<Typography component="span" sx={{  marginLeft: '4px',flexBasis:'70%' }}>: {value}</Typography>} 
        </Box>
})}
     
            <textarea value={formData.comments} onChange={(e)=> handleChange('comments',e.target.value)} rows={5} style={{resize:'none',width:'95%',border:'1.5px solid #E2E8F0',borderRadius:'4px',padding:'10px',fontFamily:'Urbanist', fontWeight:500,fontSize:'16px'}} placeholder={'Enter the internal comments'}></textarea>
            {formErrors.comments ?formErrors.comments:'' }

          <Stack direction="row" mt={2} mb={3}>
            <Button
              onClick={handleSave}
              sx={{
                backgroundColor: '#3C50E0',
                color: '#fff',
                px: 6,
                '&:hover': {
                  backgroundColor: '#3C50E0',
                  color: '#fff',
                },
              }}
            >
              {save}
            </Button>
            <Button
              onClick={() => setPopup(false)}
              sx={{ backgroundColor: '#fff', color: '#000', border: '#E2E8F0 1px solid', ml: 2 }}
            >
              {cancel}
            </Button>
          </Stack>

         
        </Box>
      </Box>
    </Modal>
  );
}
