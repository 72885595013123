import { InputAdornment } from "@mui/material";
import lockicon from "../../../../images/lockIcon.png";


export default function configNewTeam(values) {
    return [
      {
        shape: "div",
        type: "wrapper",
        className: "",
        sx: {
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridColumnGap: "100px",
        },
        children: [
          {
            shape: "input",
            type: "text",
            name: "match_week_id",
            // handleChange: "",
            className: "border",
            title: "Match Week ID",
            label: "Match Week ID",
            placeholder: "ID",
            width: "100%",
            isDisabled : true,
            inputAdornments:{
                end: <InputAdornment position="end"> <img src={lockicon} alt = 'lockicon'/></InputAdornment>,
              }
            // validation: {
            //   required: true,
            // },
          },
          {
            shape: "input",
            type: "select",
            name: "league",
            // handleChange: "",
            className: "border",
            title: "League",
            label: "League",
            options: values.leagues,
            placeholder: "",
            width: "100%",
            validation: {
              required: true,
            },
          }
        ],
      },
      {
        shape: "div",
        type: "wrapper",
        className: "",
        sx: {
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridColumnGap: "100px",
        },
        children: [
          {
            shape: "input",
            type: "text",
            name: "match_week_name",
            // handleChange: "",
            className: "border",
            title: "Match Week name",
            label: "Match Week name",
            placeholder: "Enter Match week name",
            width: "100%",
            validation: {
              required: true,
            },
          }
        ],
      },
      {
        shape: "div",
        type: "wrapper",
        className: "",
        sx: { display: "flex", justifyContents: "flex-start", mt: 6 },
        children: [
          {
            shape: "button",
            type: "action",
            title: values.mode === "view" ? "Edit" : "Add",
            submitButton : true,
            name: "Add",
            
            validation: {
              hiddenRules: [
                {
                  mode: "view",
                },
              ],
            },
          },
          {
            shape: "button",
            type: "cancel",
            title: "Cancel",
            variant: "outlined",
            name: "cancel",
      
          },
         
        ],
      },
    
    ];
  }
  