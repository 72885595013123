import { useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import TransTable from "../common/TransTable";
import _ from "lodash";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { PageTitle } from "../common/PageTitle";
import { useDispatch } from "react-redux";
import { setSelectedUser, setFeacthData } from "../../redux/action";
import { useLocation, useNavigate } from "react-router-dom";
import Axios from "axios";
import { base_url } from "../backendurl/url";
import { useState } from "react";
import { utcTimetoLocalTimeWithzone } from "../common/TransForm/Localdateformater";
import { Config } from "../../utils/commonFuctions/Token";
import { RefreshToken } from "../../utils/commonFuctions/RefreshToken";
import { Notification } from "../common/Notification";

export default function AutoFeachFixtures() {
    const navigate = useNavigate();
    const { feacthData } = useSelector((state) => state);
    const [loading, setLoading] = useState(false)
    const [notification, setnotification] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const dispatch = useDispatch();
    const location = useLocation();
    let { state } = location;

    const fixtureTableColumns = () => [
        {
            key: "match_week",
            label: "match_week",
            align: "left",
            fontSize: '15px',
            valueRenderer: (data) => {
                return (
                    data["match_week"]
                );
            },
        },
        { key: "hometeam", label: "Home Team", align: "left", fontSize: "15px" },
        { key: "awayteam", label: "Away Team", align: "left", fontSize: "15px" },
        { key: "venue", label: "Venue", align: "left" },
        {
            key: "start", label: "Match Date", align: "left", fontSize: "15px",
            valueRenderer: (data) => {
                let date = utcTimetoLocalTimeWithzone(data["start"])
                const [datePart, timePart] = date.split(', ');
                return (
                    <Typography> {datePart} </Typography>
                );
            },
        },
        {
            key: "Start", label: "Start Time", align: "left", fontSize: "15px",
            valueRenderer: (data) => {
                let date = utcTimetoLocalTimeWithzone(data["start"])
                const [datePart, timePart] = date.split(', ');
                return (
                    <Typography> {timePart}</Typography>
                );
            },
        },];

    const getUsers = useCallback(async () => {
        try{
            if (!loading) {

                setLoading(true)
                const response = await Axios.get(`${base_url}/api/pot/fixtures?season=${state?.season_year}&league=${state?.id}`, Config)
                if (response?.status == 200) {
                    dispatch(setFeacthData(response?.data?.data));
                }
                setLoading(false)
            }
        }
       catch(error){
        RefreshToken(error,setErrorMessage,setnotification) 
       }
    }, [feacthData]);

    const gotoSave = () => {
        navigate("add");
        dispatch(setSelectedUser({}));
    };
    const gotoclose = () => {
        navigate(-1);
    };

    const gotToEditUser = (data) => {
        dispatch(setSelectedUser(data));
        navigate(`edit/${data.id}`);
    };
    const gotToViewUser = (data) => {
        navigate(`view/${data.id}`);
        delete data?.id
        dispatch(setSelectedUser(data));
    };

    useEffect(() => {
        getUsers();
    }, []);


    return (
        <Box sx={{ width: '100%', typography: 'body1', }}>
            <Stack direction={'row'} sx={{ justifyContent: 'space-between', px: 6, pt: 3 }} alignContent="center">
                <PageTitle pageTitle={'Auto Fetch Fixtures'} />
                {/* <Box>
                    <Button sx={{ backgroundColor: '#3C50E0', color: '#EFF4FB', '&:hover': { backgroundColor: '#3C50E0', color: '#EFF4FB' } }} onClick={gotoSave} >Save</Button>
                    <Button sx={{ backgroundColor: '#E2E8F0', color: '#1C2434', '&:hover': { backgroundColor: '#E2E8F0', color: '#1C2434' } , ml:3 }} onClick={gotoclose} >Close</Button>
                </Box> */}
            </Stack>
            <Divider sx={{ my: 2, height: 4, mx: 4 }} />
            <Stack direction={'row'} sx={{ px: 6, pt: 3 }} alignContent="center">
                <Typography sx={{ color: '#64748B', fontSize: '16px', mr: 5 }}>League ID: <span style={{ color: '#08162A', fontWeight: 500 }}>{state.league} </span></Typography>
                <Typography sx={{ color: '#64748B', fontSize: '16px', mr: 5 }}>League Name: <span style={{ color: '#08162A', fontWeight: 500 }}> {state.leaguename} </span> </Typography>
                <Typography sx={{ color: '#64748B', fontSize: '16px', mr: 5 }}>Season: <span style={{ color: '#08162A', fontWeight: 500 }}> {state.season_year}</span> </Typography>

            </Stack>
            <TransTable
                showAddBtn={false}
                showSearch={false}
                checkbox={false}
                columns={fixtureTableColumns({ gotToEditUser, gotToViewUser })}
                rows={feacthData}
            />
        <Notification message={errorMessage} setopen={setnotification} type={'error'} show={notification} />

        </Box>
    );
}
