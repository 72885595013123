import React, { useEffect, useState } from 'react';
import { Modal, TextField, Button, Select, MenuItem, Typography, Box, Grid, Stack, Divider } from '@mui/material';
import { localTimeWithZoneToUTCTime, utcTimetoLocalTimeWithzone } from '../common/TransForm/Localdateformater';
import axios from 'axios';
import { base_url } from '../backendurl/url';
import { Config } from '../../utils/commonFuctions/Token';
import { RefreshToken } from '../../utils/commonFuctions/RefreshToken';
import { Notification } from '../common/Notification';

export default function AddFixture({ openpopup, onClose, onAddRow, state }) {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 665,
        height: 450,
        bgcolor: 'background.paper',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
        borderRadius: '8px'

    };
    const [matchWeekOpt, setMatchweekOpt] = useState([])
    const [teamsOpt, setTeamsOpt] = useState([])
    const [formErrors, setFormErrors] = useState({});
    const [notification, setnotification] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const headers = [
        {
            label: 'Match Week',
            name: 'match_week',
            type: 'select',
            options: matchWeekOpt,
            show: true,
            valueRenderer: (data) => {
                return data['match_week'];
            },
        },
        {
            label: 'Home Team',
            name: 'hometeam',
            type: 'select',
            options: teamsOpt.map(item=> item.name),
            show: true,
            valueRenderer: (data) => {
                return data['hometeam'];
            },
        },
        {
            label: 'Away Team',
            name: 'awayteam',
            type: 'select',
            options:  teamsOpt.map(item=> item.name),
            show: true,
            valueRenderer: (data) => {
                return data['awayteam'];
            },
        },
        {
            label: 'Venue',
            name: 'venue',
            type: 'text',
            show: true,
            valueRenderer: (data) => {
                return data['venue'];
            },
        },
        {
            label: 'Start Date',
            name: 'start',
            type: 'date',
            show: true,
            valueRenderer: (data) => {
                let date = utcTimetoLocalTimeWithzone(data["start"]);
                const [datePart, timePart] = date.split(', ');
                return (
                    <Typography> {datePart} </Typography>
                );
            },
        },
        {
            label: 'Start Time',
            name: 'time',
            type: 'time',
            show: true,
            valueRenderer: (data) => {
                let date = utcTimetoLocalTimeWithzone(data["start"]);
                const [datePart, timePart] = date.split(', ');

                return (
                    <Typography> {timePart} </Typography>
                );
            },
        },
    ];


    const [newRowData, setNewRowData] = useState({});
  

    const handleNewRowChange = (value, fieldName) => {
        setNewRowData((prevData) => ({ ...prevData, [fieldName.toLowerCase()]: value }));
        if (headers.type === 'select' && !formErrors[headers.name.toLowerCase()]) {
            setFormErrors((prevErrors) => ({ ...prevErrors, [fieldName.toLowerCase()]: '' }));
        }
    };



    const validateForm = () => {
        const errors = {};
        headers.forEach((header) => {
            if (header.show && header.type !== 'select') {
                if (!newRowData[header.name.toLowerCase()]) {
                    errors[header.name] = `${header.label} is required`;
                }
            } else if (header.show && header.type === 'select') {
                if (!newRowData[header.name.toLowerCase()]) {
                    errors[header.name] = `Please select a ${header.label}`;
                }
            }
            if(header.name == 'awayteam'){
                if (newRowData['awayteam']==newRowData['hometeam']) {
                    errors[header.name] = `Home and away team cannot be the same`;
                }
            }
        });
        return errors;
    };

    const handleAddNewRow = () => {
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length === 0) {
            let awayteam = teamsOpt.find(item => item.name == newRowData.awayteam)
            let hometeam = teamsOpt.find(item => item.name == newRowData.hometeam)

            newRowData.start = newRowData.start + 'T' + newRowData.time + ':00Z'
            newRowData.start = localTimeWithZoneToUTCTime(newRowData.start)
            newRowData.end = new Date(new Date(newRowData.start).getTime() + 2 * 60 * 60 * 1000).toISOString()
            newRowData.leagues_season = state?.season_id
            newRowData.status = 'NS'
            newRowData.result = 'None'
            newRowData.fixture_id = ''
            newRowData.awayteam_goal = 0
            newRowData.hometeam_goal = 0
            newRowData.awayteam_logo = awayteam.logo
            newRowData.hometeam_logo = hometeam.logo
            onAddRow(newRowData);
            setNewRowData({});
            onClose();
        } else {
            setFormErrors(validationErrors);
        }
    };


    const getTeamsmatchweek = async () => {
        try {
            const matchWeekResponse = await axios.get(`${base_url}/api/pot/matchweek?league=${state.league}`, Config);
            const teamResponse = await axios.get(`${base_url}/api/pot/teams?league=${state.league}`, Config);
            setMatchweekOpt(matchWeekResponse?.data?.data?.map((item) => item.match_week_name) || []);
            setTeamsOpt(teamResponse?.data?.data?.map((item) => {return { name:item?.name,logo:item?.logo}}) || []);
            setFormErrors({})
        } catch (error) {
            RefreshToken(error,setErrorMessage,setnotification)  
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {
        getTeamsmatchweek()
    }, [])


    return (
        <Modal open={openpopup} onClose={onClose} >

            <Box sx={style}>
                <Typography variant="h6" component="h2" sx={{ px: 6, my: 2, alignItems: "center" }} >
                    Add Match 
                </Typography>
                <Divider />
                <Grid container spacing={4} justifyContent="center" sx={{ p: 2, mt:'0 !important '}}>
                    {headers.map((header, index) => (
                        <Grid item xs={12} sm={6} key={index} >
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={12} sm={6}>
                                    <span>{header.label}:</span>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {header.type === 'select' ? (
                                        <Select
                                            sx={{
                                                width: '140px', height: '30px',
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none !important', // Remove one of the borders
                                                },
                                                '& .MuiSelect-select': {
                                                    height: '10px !important',
                                                    minHeight: '10px !important'
                                                },
                                                
                                            }}
                                            value={newRowData[header.name.toLowerCase()] || ''} onChange={(e) => handleNewRowChange(e.target.value, header.name)}>
                                            {header.options.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                                                             
                                    ) : header.type === 'text' ? (
                                        <TextField sx={{ width: '140px', height: '40px' }} value={newRowData[header.name.toLowerCase()] || ''} onChange={(e) => handleNewRowChange(e.target.value, header.name)} />
                                    ) : header.type === 'date' ? (
                                        <TextField sx={{ width: '140px', height: '40px',textAlign:'center' }} type="date" value={newRowData[header.name.toLowerCase()] || ''} onChange={(e) => handleNewRowChange(e.target.value, header.name)} />
                                    ) : header.type === 'time' ? (
                                        <TextField sx={{ width: '140px', height: '40px' }} type="time" value={newRowData[header.name.toLowerCase()] || ''} onChange={(e) => handleNewRowChange(e.target.value, header.name)} />
                                    ) : null}
                                    {formErrors[header.name.toLowerCase()] && (
                                        <Typography variant="caption" color="error">
                                            {formErrors[header.name.toLowerCase()]}
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
                <Stack direction="row" mt={3} justifyContent="center">
                    <Button
                        onClick={handleAddNewRow}
                        sx={{
                            backgroundColor: '#3C50E0',
                            color: '#fff',
                            px: 6,
                            '&:hover': {
                                backgroundColor: '#3C50E0',
                                color: '#fff',
                            },
                        }}
                    >
                        save
                    </Button>
                    <Button
                        onClick={() => onClose(false)}
                        sx={{ backgroundColor: '#fff', color: '#000', border: '#E2E8F0 1px solid', ml: 2 }}
                    >
                        cancel
                    </Button>
                </Stack>
                <Notification message={errorMessage} setopen={setnotification} type={'error'} show={notification} />
            </Box>
        </Modal>
    )
}
