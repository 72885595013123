import { InputAdornment } from "@mui/material";
import calendar from '../../../../images/calendar.png'

export default function FormLeaguesDetails(values) {
    return [
        {
            shape: "div",
            type: "wrapper",
            className: "",
            sx: {
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gridColumnGap: "100px",
            },
            children: [
                {
                    shape: "input",
                    type: "date",
                    name: "start_date",
                    // title: "Start Date",
                    label: "Start Date",
                    placeholder: "Date",
                    // isDisabled: !values.leagueDetails,
                    width: '100%',
                    validation: {
                        required: true,
                    },
                    inputAdornments:{
                        end: <InputAdornment position="end"> <img src={calendar} alt = 'calendar'/></InputAdornment>,
                      }
                },
                {
                    shape: "input",
                    type: "date",
                    name: "end_date",
                    // title: "End Date",
                    label: "End Date",
                    placeholder: "Date",
                    // isDisabled: !values.leagueDetails,
                    width: '100%',
                    validation: {
                        required: true,
                    },
                    inputAdornments:{
                        end: <InputAdornment position="end"> <img src={calendar} alt = 'calendar'/></InputAdornment>,
                      }
                },
            ],
        },
        {
            shape: "div",
            type: "wrapper",
            className: "",
            sx: {
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gridColumnGap: "100px",
            },
            children: [
                {
                    shape: "input",
                    type: "text",
                    name: "total_teams",
                    // handleChange: "",
                    className: "border",
                    title: "No. of teams",
                    label: "No. of teams",
                    placeholder: "Enter number of teams",
                    // isDisabled: !values.leagueDetails,
                    width: "100%",
                    validation: {
                        required: true,
                    },
                },
                {
                    shape: "input",
                    type: "text",
                    name: "total_matches",
                    // isDisabled: !values.leagueDetails,
                    // handleChange: "",
                    className: "border",
                    title: "No. of matches",
                    label: "No. of matches",
                    placeholder: "Enter number of matches",
                    width: "100%",
                    validation: {
                        required: true,
                    },
                }
            ],
        },
        {
            shape: "div",
            type: "wrapper",
            className: "",
            sx: {
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gridColumnGap: "100px",
            },
            children: [
                {
                    shape: "input",
                    type: "text",
                    name: "total_match_week",
                    // handleChange: "",
                    className: "border",
                    // isDisabled: !values.leagueDetails,
                    title: "No. of match weeks/days",
                    label: "No. of match weeks/days",
                    placeholder: "Enter number of match weeks/days",
                    width: "100%",
                    validation: {
                        required: true,
                    },
                },
                {
                    shape: "input",
                    type: "file",
                    name: "league_logo",
                    // handleChange: handleChange,
                    uploadType: 'inlineDocument',
                    mode:'view',
                    handleChange: "",
                    className: "border",
                    isDisabled: values?.isSystemLeagueSelected,
                    title: "logo",
                    label: "League Logo",
                    placeholder: "League Logo",
                    fileRestriction : {height:200,width:200},
                    width: "100%",
                    accept:'image/*',
                      validation: {
                        
                        required: !values?.isSystemLeagueSelected,
                      },
                  }
            ],
        },
        {
            shape: "div",
            type: "wrapper",
            className: "",
            sx: { display: "flex", justifyContents: "flex-start", mt: 6 },
            children: [
                {
                    shape: "button",
                    type: "action",
                    title: 'Fetch Fixtures',
                    // submitButton: true,
                    name: "fetch_ixtures",
                    isDisabled: !values?.isSystemLeagueSelected,
                    sx: {
                        mr: 2,
                       
                    },
                    validation: {
                        hiddenRules: [
                            {
                                mode: "view",
                            },
                        ],
                    },
                },
                {
                    shape: "button",
                    type: "action",
                    title: 'Manually Add Fixtures',
                    // submitButton: true,
                    variant: "outlined",
                    name: "manually_add_fixtures",
                    isDisabled: values.isSystemLeagueSelected,
                    sx: {
                        mr: 2,
                        
                    },
                    validation: {
                        hiddenRules: [
                            {
                                mode: "view",
                            },
                        ],
                    },
                }
            ],
        },

    ];
}
