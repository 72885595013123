import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  Link,
  Button,
  Drawer,
  Typography,
  Avatar,
  Stack,
} from "@mui/material";
// hooks
import useResponsive from "../../hooks/useResponsive";
// components
import Logo from "../../components/common/Logo";
import Scrollbar from "../../components/common/Scrollbar";
import NavSection from "../../components/common/NavSection";
//
import navConfig from "./NavConfig";
import { useSelector } from "react-redux";
import collapsearrow from '../../images/collapsearrow.png'
import expandarrow from '../../images/expandArrow.png'
// ----------------------------------------------------------------------

const DRAWER_WIDTH = '19%';

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const isDesktop = useResponsive("up", "lg");

  const RootStyle = styled("div")(({ theme }) => ({
      flexShrink: 0,
      width: isOpenSidebar ? 65 : DRAWER_WIDTH,
    
  }));


  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: "inline-flex" }}>
         <Logo iscollapse={isOpenSidebar}/>
      </Box>
      <NavSection navConfig={navConfig} iscollapse={isOpenSidebar}/>

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      <Drawer
        open
        variant="persistent"
        PaperProps={{
          sx: {
            width: isOpenSidebar ? 65 : DRAWER_WIDTH,
            bgcolor: "#08162B",
            color: '#717C90',
            borderRightStyle: "dashed",
            overflowX:isOpenSidebar ? 'hidden !important' : 'auto',
          },
        }}
      >
        <Box sx={{position: 'relative',  display: 'flex', justifyContent: 'end', left: '30px', top:'20px' }}>
          <Button onClick={onCloseSidebar}  sx={{position:'fixed'}} >
            {isOpenSidebar ? <img src={expandarrow} style={{width:'25px'}}  onClick={onCloseSidebar}  /> : <img src={collapsearrow}  style={{width:'25px'}}  onClick={onCloseSidebar}/>}
          </Button>
        </Box>
        {renderContent}

      </Drawer>
    </RootStyle>
  );
}
