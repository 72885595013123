import { RefreshToken } from "./RefreshToken"

function tokenConfig() {
    
    let token =  localStorage.getItem('token')

    const getCookie = (name) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2){
        return parts.pop().split(';').shift()
      } ;
      return null;
    };

    return   {headers: {
      "Authorization": 'Bearer ' + getCookie('token')
    }
  }
}



export const Config = tokenConfig()


