import { Box, Typography } from "@mui/material";
import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Legend,
  Label,
} from "recharts";
import DateRangeFilter from "./DateRangeFilter";
import MultiSelectFilter from "./MultiSelectFilter";
import Excel from "../../images/Excel.svg";
import axios from "axios";
import { Config } from "../../utils/commonFuctions/Token";

const CustomPieChart = ({
  data = [],
  title,
  filters = null,
  filterData,
  getUrl,
  setData,
  setfilterData,
  getFunction,
  align,
  filterkey,
  options,
  downloadUrl
}) => {
  // sx={{ width: '100%',height:'300px',backgroundColor:'#fff',padding: 4 }}
  const isAllDataZero = data.every((entry) => entry.value === 0);
  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  
  function formatExcelDate(date) {   
    const yy = String(date.getFullYear()).slice(-2);  
     const mm = String(date.getMonth() + 1).padStart(2, '0');   
     const dd = String(date.getDate()).padStart(2, '0');   
     const hh = String(date.getHours()).padStart(2, '0');  
      const min = String(date.getMinutes()).padStart(2, '0');   
      const ss = String(date.getSeconds()).padStart(2, '0');
       return yy + mm + dd + hh + min + ss; }
  
  const downloadExcel = async() =>{
  let  url = downloadUrl
  if(filterData[filterkey]){
    if(filters=='date' ){
      url = downloadUrl + `&end=${formatDate(filterData[filterkey][0].endDate)}&start=${formatDate(filterData[filterkey][0].startDate)}`
    }else{
      url = downloadUrl + `&league=${filterData[filterkey]
        .map((item) => item.value)
        .join(",")
        .replace(",", "")}`
    }
  }
     
        const excelResponse = await axios.get(
          url,
          Config
        );
        const file = new Blob([excelResponse?.data], { type: 'text/csv' });
    // process to auto download it
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = fileURL;
    link.download = title +'_'+formatExcelDate(new Date()) + ".csv";
    link.click();
        
  }

  
  return (
    <Box
      sx={{
        height: 430,
        width: "32vw",
        position: "relative",
        backgroundColor: "#fff",
      }}
    >
      <Box
        sx={{
          display: "flex",
          padding: 3,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>
          {" "}
          {title}{" "}
          
         
        </Typography>
        <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center',textAlign:'center'}}>
        {filters == "date" ? (
          <DateRangeFilter
            onChange={(item) => {
              filterData[filterkey] = item;

              setfilterData(filterData);
              getFunction(getUrl, item);
            }}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={filterData}
            align={align}
            filterJSON={{ apiKey: filterkey }}
            direction="horizontal"
          />
        ) : (
          <MultiSelectFilter
            options={options ? options : []}
            onChange={(item) => {
              filterData[filterkey] = item;
              let filterValueList = item.slice(1);
              let filterValue = filterValueList
                .map((item) => item.value)
                .join(",")
                .replace("League,", "");
              setfilterData(filterData);
              getFunction(getUrl, filterValue);
            }}
            filterData={filterData}
            filterKey={filterkey}
          />
        )}
        <Box sx={{ padding: "6px", marginLeft: "10px", background: "#F0F0F0",cursor:'pointer'}}>
          <img src={Excel} alt="excel" onClick={downloadExcel} />
        </Box>
        </Box>
      </Box>

      <Box sx={{ height: "250px", width: "400px", position: "absolute" }}>
        <ResponsiveContainer>
          <PieChart>
            <Pie
              width={300}
              height={400}
              data={
                isAllDataZero
                  ? [{ name: "No Data", value: 1, color: "#ccc" }]
                  : data
              }
              innerRadius={50}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              {/* {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color}  />
          ))} */}
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
              {/* <Label value={data.name} position="center" /> */}
            </Pie>
            {/* <Legend /> */}
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
        <Box sx={{display:'flex',flexWrap:'wrap',width:'100%',marginLeft:2,justifyContent:'center'}}>
          {data.map(item=>{

            return <Box sx={{display:'flex',padding:1,alignItems:'center',fontSize:'10px',marginRight:0}}>
              <Box sx={{backgroundColor:item.color,width:'15px',height:'15px',marginRight:1}}></Box>
            <Typography sx={{fontSize:'12px'}}>{item.name}</Typography>
            </Box>
           
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default CustomPieChart;
