import React, { useState } from 'react'
import Modal from '@mui/material/Modal';
import deactive from '../../../images/deactive.png'
import CurrencyInput from '../../common/TransForm/CurrencyInput'

import { Box, Button, Grid, Input, Typography } from "@mui/material";
import { PnpSelect } from './PnpSelect';

export default function ModalPopUp({title,subTitle,popupType='default',onChange,save,cancel,openpopup,textArea,saveBtnBg,setPopup,onClick,sx,values}) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        // width: 665,
        // height:224,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        ...sx
      };

 const getPopupType = () =>{
 

  if(popupType == 'player'){
    return<Box sx= {{justifyContent:"center",display:'flex'}}> <Input
    sx={{mt:5,width:'250px'}}
    type = {'number'}
    onChange={onChange}
    
    />
    </Box>
  }else if(popupType =='payout'){
    return<Box >
      <CurrencyInput name={'Amount'} countryCode={values.countryCode} value={values.amount} onChange={onChange} selectDisable={true}/>

      {/* <PnpSelect sx={{mt:5}} name={'payment'} value={values.paymentMode} options={[{'id':'Cash','name':'Cash'},{'id':'Online','name':'Online'},{'id':'UPI','name':'UPI'}]} onChange={onChange}/> */}
    </Box>
  }
 
 }
  return (
    <div>
         <Modal
        open={openpopup}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {textArea && <img src={deactive} alt='deactive'/>}
        

          <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {subTitle}
          </Typography>
          
          {/* {textArea ?
          <Box sx = {{mx:'auto',textAlign : 'center',}}>
  <textarea placeholder='Enter the reason to deactivate' style={{
    width: "500px", // Set the width here
    height: "100px",
    resize : 'none',
    border : '1px solid #000',
    padding : '2%'
     // Set the height here
  }}></textarea> 
          </Box>
        : '' } */}

{popupType=='default' ? textArea ?
          <Box sx = {{mx:'auto',textAlign : 'center',}}>
  <textarea placeholder='Enter the reason to deactivate' style={{
    width: "500px", // Set the width here
    height: "100px",
    resize : 'none',
    border : '1px solid #000',
    padding : '2%'
     // Set the height here
  }}></textarea> 
          </Box>
        : '' 
        : getPopupType()} 

          <Box sx= {{mt:6}}>
         
          <Button onClick={onClick} sx = {{backgroundColor : saveBtnBg ? saveBtnBg:'#3C50E0',color : '#fff',px: 6,"&:hover": {
          backgroundColor:saveBtnBg?saveBtnBg : "#3C50E0", // Set background color to transparent on hover
          color: "#fff", // Use the inherit color on hover
        },}}>
          {save}
          </Button>
          <Button onClick={()=>setPopup(false)} sx = {{backgroundColor : '#fff',color : '#000',border : '#E2E8F0 1px solid',ml:2}}>
         {cancel}
          </Button>
          </Box>
          
        </Box>
      </Modal>
    </div>
  )
}
