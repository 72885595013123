import { Typography } from "@mui/material";
import Iconify from "./Iconify";
import { Box } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import {
//   setSelectedInstitutions,
//   setSelectedTransporter,
//   setSelectedUser,
// } from "../../redux/action";

export const PageTitle = ({ pageTitle, backBtn = false }) => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  const goBack = () => {
    navigate(-1);
  };
  return (
    <Box display="flex" mb={1}>
      {backBtn && (
        <Iconify
          onClick={goBack}
          icon="eva:chevron-left-fill"
          fontSize="28px"
          sx={{ cursor: "pointer" }}
        />
      )}
      <Typography sx={{ fontSize: 24, fontWeight: 600 }}>
        {" "}
        {pageTitle}
      </Typography>
    </Box>
  );
};
