import { useSelector } from "react-redux";
import { React, useCallback, useEffect, useState } from "react";
import TransTable from "../../common/TransTable";
import _ from "lodash";
import {
  Box,
  Typography,
  Stack,
} from "@mui/material";
import { PageTitle } from "../../common/PageTitle";
import { useDispatch } from "react-redux";
import { setPotSetting, setSelectedTeam } from "../../../redux/action";
import { Link, useNavigate, useParams } from "react-router-dom";
import Axios from "axios";
import { base_url } from "../../backendurl/url";
import ModalPopUp from "../../common/TransForm/ModalPopUp";
import { TransButton } from "../../common/TransButton";
import { Config } from "../../../utils/commonFuctions/Token";
import { RefreshToken } from "../../../utils/commonFuctions/RefreshToken";
import { Notification } from "../../common/Notification";
import Excel from "../../../images/Excel.svg";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

function PotIdDetails() {

  const navigate = useNavigate();
  const [popup, setPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const { selectedTeam, PotSetting } = useSelector((state) => state);
  const { id } = useParams("id");
  const [loading, setLoading] = useState(false);
  const [notification, setnotification] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const dispatch = useDispatch();

  // File Extension and Type for excel download
  const fileType ="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const potDetails = [
    { key: "username", label: "Players", align: "left", fontSize: "15px" },
    { key: "id", label: "Pick ID", align: "right", fontSize: "15px" },
  ];

  const potExcelDetails = [
    { key: "id", label: "Pick ID", align: "right", fontSize: "15px" },
    { key: "username", label: "Players", align: "left", fontSize: "15px" },
    { key: "player_email", label: "Email", align: "left", fontSize: "15px" },
    { key: "player_mobilenumber", label: "Mobile Number", align: "left", fontSize: "15px"},
  ];

  const getPotDetails = useCallback(async () => {
    try{
      if (!loading) {
        setLoading(true);
        dispatch(setPotSetting([]))
        dispatch(setSelectedTeam([]));
        const response = await Axios.get(`${base_url}/api/pot/pots/${id}`, Config);
        if (response?.status == 200) {
          dispatch(setPotSetting(response?.data?.data))
          dispatch(setSelectedTeam(response?.data?.data?.pot_players));
        }
        setLoading(false);
      }
    }
   catch(error){
    RefreshToken(error,setErrorMessage,setnotification)
   }
  }, []);

  const downloadExcel = async (jsonData,fileName) => {

    const filteredData = jsonData.map((item) => {
      const newItem = {};
      potExcelDetails.forEach((col) => {
        if (col.valueRenderer) {
          newItem[col.label] = col.valueRenderer(item);
        } else {
          newItem[col.label] = item[col.key];
        }
      });
      return newItem;
    });
    
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  }

  const SubmitChange = async () => {
    try{
      PotSetting.status = "Active";
      const response = await Axios.put(`${base_url}/api/pot/pots/${id}`, PotSetting, Config);
      if (response?.status == 200) {
        navigate(-1)
      }
      setPopup(false);
    }
    catch(error){
      RefreshToken(error,setErrorMessage,setnotification)
    }
  }

  const deletePot = async () => {
    let potdata = {
      pot: id
    }
    try {
      const response = await Axios.post(`${base_url}/api/pot/closepot`, potdata, Config);
      if (response?.status == 200) {
        navigate(-1)
      }
      setPopup(false);
    }
    catch (error) {
      RefreshToken(error, setErrorMessage, setnotification)
    }
  }

  const potActivate = () => {
    setPopup(true)
  }
  const potDelete = () =>{
    setDeletePopup(true)
  }
  useEffect(() => {
    getPotDetails();
  }, []);

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <Stack
        direction={"row"}
        sx={{ justifyContent: "space-between", px: 6, pt: 3 }}
        alignContent="center"
      >
        <PageTitle pageTitle={"Round ID Details"} />
        <Box sx={{ marginLeft: "10px", cursor: 'pointer' }}>
          <img width={'50px'} src={Excel} alt="excel" onClick={()=>downloadExcel(selectedTeam,"Waitlist_Rounds")} />
        </Box>
      </Stack>
      <Box sx={{ display: "flex", px: 6 }}>
        <Typography sx={{ pr: "3%" }}>
          {" "}
          <span style={{ color: "#64748B" }}>Round ID:</span> {PotSetting?.id}
        </Typography>
        <Typography sx={{ pr: "3%" }}>
          {" "}
          <span style={{ color: "#64748B" }}>Round Category:</span> {PotSetting?.category}
        </Typography>
        <Typography sx={{ pr: "3%" }}>
          {" "}
          <span style={{ color: "#64748B" }}>League:</span>  {PotSetting?.league}
        </Typography>
        <Typography sx={{ pr: "3%" }}>
          {" "}
          <span style={{ color: "#64748B" }}>Round Value:</span>{PotSetting?.pot_currency} {PotSetting?.current_pot_value}
        </Typography>
      </Box>
      <TransTable
        showSearch={false}
        checkbox={false}
        columns={potDetails}
        rows={selectedTeam}
        showAddBtn={false}
        tableAlign={'left'}
        isLoading={loading}
      />
      <Box sx={{ display: 'flex', px: 6 }}>
        <TransButton title={'Activate'} onClick={potActivate} />
        <TransButton onClick={() => navigate('/rounds')} title={'Back'} sx={{ background: '#fff', color: '#1c2434', border: '1px solid #E2E8F0', '&:hover': { backgroundColor: 'transparent' }, }} />
        <TransButton title={'Delete'} sx={{ background: '#d11a2a ', color:'#fff', border: '1px solid #E2E8F0', '&:hover': { backgroundColor:'#d11a2a ' }, }} onClick={potDelete} />
      </Box>
      <ModalPopUp
        openpopup={popup}
        setPopup={setPopup}
        title={'Activating Round ?'}
        subTitle={'Are you sure, you want to activate the Round ? '}
        onClick={SubmitChange}
        save={'Yes, Activate'}
        cancel={'Exit'}
        sx={{ borderRadius: '2px' }}
      />
      <ModalPopUp
        openpopup={deletePopup}
        setPopup={setDeletePopup}
        title={'Delete this Round ?'}
        subTitle={'Are you sure, you want to delete this Round ? '}
        onClick={deletePot}
        save={'Yes, Delete'}
        cancel={'Exit'}
        sx={{ borderRadius: '2px' }}
      />
      <Notification message={errorMessage} setopen={setnotification} type={'error'} show={notification} />

    </Box>

  );
}

export default PotIdDetails;
