import { useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import TransTable from "../common/TransTable";
import _ from "lodash";
import { Box, Button, IconButton, Typography } from "@mui/material";

import { setSelectedUser } from "../../redux/action";
import { Link, useNavigate } from "react-router-dom";
import Iconify from "../common/Iconify";

import Axios from "axios";
import { base_url } from "../backendurl/url";
import { useState } from "react";
import { Config } from "../../utils/commonFuctions/Token";
import { RefreshToken } from "../../utils/commonFuctions/RefreshToken";
import { Dateformater } from "../common/TransForm/dateFormater";
import { utcTimetoLocalTimeWithzone } from "../common/TransForm/Localdateformater";
import { currencyformater } from "../common/TransForm/currencyformater";
import { Notification } from "../common/Notification";

export default function Purchases() {
  const [loading, setLoading] = useState(false)
  const [purchases, setPurchases] = useState([])
  const [notification, setnotification] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  

  const PurchasesTableColumns = (params) => [
    {
      key: 'id',
      label: "Purchase ID",
      align: "left",
      fontSize: '15px'
    },
    { key: 'transaction_id',label: "Transaction ID",align: "left",fontSize: '15px'},
    { key: "transfer_type", label: "Payment Method", align: "left", fontSize: "15px"},
    { key: "player_name", label: "Player Name", align: "left" },
    { key: "player_email", label: "Email", align: "left" },
    { key: "player_mobilenumber", label: "Mobile Number", align: "left" },
    { key: "created_at", label: "Purchases Date", align: "left", fontSize: "15px",
    valueRenderer: (data) => {
      let date = utcTimetoLocalTimeWithzone(data["created_at"])
      const [datePart, timePart] = date.split(', ');
      return (
        <Typography sx={{width:'150px'}}> {datePart}</Typography>
        )
    },
  },
    { key: "amount", label: "Amount Paid", align: "left", fontSize: "15px",
    valueRenderer: (data) => {
      return (
        <Typography sx={{ width: "150px" }}>
          {" "}
          {data.currency_code} {currencyformater(data["amount"])}
        </Typography>
      );
    },
   },
   
    {
      key: "transaction_status",
      label: "Payment Status",
      align: "center",
      fontSize: '14px',
      
      valueRenderer: (data) => {
        const colors = {
          Success :{ color:'#219653',backgroundColor:'#21965333'},
          Failure:{ color:'#E42C2C',backgroundColor:'#FFE8E1'},
          Idle:{ color:'#DD8A0D',backgroundColor:'#FEFFE1'}
        }
      return <Button sx={{

        backgroundColor: `${colors[data?.transaction_status] ? colors[data?.transaction_status]?.backgroundColor :'#FFE8E1'}`,
         color:`${colors[data?.transaction_status]?.color ?colors[data?.transaction_status]?.color:'#E42C2C'}`}}>{data?.transaction_status}</Button>
      },
    },

    // {
    //   key: "action",
    //   label: "Action",
    //   align: "center",
    //   valueRenderer: (data) => {
    //     return (
    //       <Box>
    //         <IconButton onClick={() => params.gotToViewUser(data)}>
    //           <Iconify icon="eva:eye-outline" size="small" color="primary.main" />
    //         </IconButton>
    //       </Box>
    //     );
    //   },
    // },
  ];


  const PurchasesExcelTableColumns = (params) => [
    { key: 'transaction_id',label: "Transaction ID",align: "left",fontSize: '15px'},
    { key: "player_name", label: "Player Name", align: "left" },
    { key: "player_email", label: "Email", align: "left" },
    { key: "player_mobilenumber", label: "Mobile Number", align: "left" },
    {
      key: "created_at", label: "Purchases Date", align: "left", fontSize: "15px",
      valueRenderer: (data) => {
        let date = utcTimetoLocalTimeWithzone(data["created_at"])
        const [datePart, timePart] = date.split(', ');
        return datePart
      },
    },
    {
      key: "amount", label: "Amount Paid", align: "left", fontSize: "15px",
      valueRenderer: (data) => {
        return `${data.currency_code} ${currencyformater(data["amount"])}`
      },
    },

    {
      key: "transaction_status",
      label: "Payment Status",
      align: "center",
      fontSize: '14px',

      valueRenderer: (data) => {
        const colors = {
          Success: { color: '#219653', backgroundColor: '#21965333' },
          Failure: { color: '#E42C2C', backgroundColor: '#FFE8E1' },
          Idle: { color: '#DD8A0D', backgroundColor: '#FEFFE1' }
        }
        return data?.transaction_status
      }
    },

    // {
    //   key: "action",
    //   label: "Action",
    //   align: "center",
    //   valueRenderer: (data) => {
    //     return (
    //       <Box>
    //         <IconButton onClick={() => params.gotToViewUser(data)}>
    //           <Iconify icon="eva:eye-outline" size="small" color="primary.main" />
    //         </IconButton>
    //       </Box>
    //     );
    //   },
    // },
  ];
  
  const getUsers = useCallback(async () => {
    try{
      if (!loading) {

        setLoading(true)
        const response = await Axios.get(`${base_url}/api/pot/purchases`, Config);
        if (response?.status == 200) {
          let no = 1
          let data = response?.data?.data
          data.map(item => {
            item.serial_no = no
            no += 1
          })
          setPurchases(data);
        }
        setLoading(false)
      }
    }
    catch(error){
      RefreshToken(error,setErrorMessage,setnotification)
    }
  }, []);

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <Box>
        <TransTable
          pageTitle="Purchases"
          showAddBtn={false}
          showSearch={false}
          checkbox={false}
          columns={PurchasesTableColumns()}
          excelColumns={PurchasesExcelTableColumns()}
          rows={purchases}
          isExcel={true}
          isLoading={loading}
        />
    <Notification message={errorMessage} setopen={setnotification} type={'error'} show={notification} />

      </Box>
    </>
  );
}
