// component
import Iconify from "../../components/common/Iconify";
import dashboard from '../../images/dashboardIcon.svg'
import configurations from '../../images/configurationsIcon.svg'
import contents from '../../images/contentsIcon.svg'
import purchase from '../../images/purchaseIcon.svg'
import payout from '../../images/payoutsIcon.svg'
import players from '../../images/playesIcon.svg'
import potsIcon from '../../images/potsIcon.svg'
import leagues from '../../images/leaguesIcon.svg'
import user from '../../images/user.svg'
import child from '../../images/childIcon.svg'

import dashboard1 from '../../images/dashboardwhite.svg'
import configwhite from '../../images/configwhite.svg'
import childwhite from '../../images/childwhite.svg'
import leaguewhite from '../../images/leaguewhite.svg'

// import potwhite from '../../images/potswhite.svg'
import playerswhite from '../../images/playerswhite.svg'
import payoutwhite from '../../images/payoutwhite.svg'
import userwhite from '../../images/userswhite.svg'
import pots from '../../images/whitepot.svg'
// import hastag from'../../images/hastag.svg'

import hashtag from '../../images/hashtag.svg'
import hashtag1 from '../../images/hashtag1.svg'
import purchasewhite from '../../images/purchasewhite.svg'





// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} type='icon' width={22} height={22} />;
const getImage = (img) => <Iconify icon={img} type='image' width={22} height={22} />


const navConfig = [
  {
    title: "dashboard",
    path: "/dashboard",
    icon: getImage(dashboard),
    dark_icon: getImage(dashboard1),
    // hasAccessMenu: "",
  },
  {
    title: "Leagues",
    path: "/leagues",
    // icon: getIcon("mdi:truck-fast-outline"),
    icon: getImage(leagues),
    dark_icon: getImage(leaguewhite),
    hasAccessMenu: "leagues",
  },
  {
    title: "Rounds",
    path: "/rounds",
    // icon: getIcon("mdi:office-building-outline"),
    icon: getImage(potsIcon),
    dark_icon: getImage(pots),
    hasAccessMenu: "pots"
  },
  {
    title: "Players",
    path: "/players",
    // icon: getIcon("mdi:map-marker-distance"),
    icon: getImage(players),
    dark_icon: getImage(playerswhite),
    hasAccessMenu: "user"
  },
  {
    title: "Users",
    path: "/users",
    // icon: getIcon("mdi:user-group"),
    icon: getImage(user),
    dark_icon: getImage(userwhite),
    hasAccessMenu: "user",
  },
  {
    title: "Payouts",
    path: "/payouts",
    // icon: getIcon("mdi:user-group"),
    icon: getImage(payout),
    dark_icon: getImage(payoutwhite),
    hasAccessMenu: "payout",
  },
  {
    title: "Purchases",
    path: "/purchases",
    // icon: getIcon("mdi:user-group"),
    icon: getImage(purchase),
    dark_icon: getImage(purchasewhite),
    hasAccessMenu: "paymentsreceipts"
  },
  {
    title: "Configurations",
    path: "/configurations",
    // icon: getIcon("mdi:chart-box-outline"),
    icon: getImage(configurations),
    dark_icon: getImage(configwhite),
    hasAccessMenu:"potconfig" || "configuredleagues"  || "teams" || "leaguematchweek" ,
    children: [
      {
        title: 'Round Parameters',
        path: '/configurations/roundparameters',
        icon: getImage(child),
        dark_icon: getImage(childwhite),
        hasAccessMenu: "potconfig" 
      },
      {
        title: 'Leagues',
        path: '/configurations/leagues',
        icon: getImage(child),
        dark_icon: getImage(childwhite),
        hasAccessMenu: "configuredleagues",
      },
      {
        title: 'Teams',
        path: '/configurations/teams',
        icon: getImage(child),
        dark_icon: getImage(childwhite),
        hasAccessMenu: "teams" 

      },
      {
        title: 'Match Weeks',
        path: '/configurations/matchweeks',
        icon: getImage(child),
        dark_icon: getImage(childwhite),
        hasAccessMenu: "leaguematchweek" 

      }
    ],
  },
  {
    title: 'Customer Query',
    path: '/customer-query',
    icon: getImage(hashtag1),
    dark_icon: getImage(hashtag),
    hasAccess: true,
    hasAccessMenu: "tickets"
  }
  // {
  //   title: "Contents",
  //   // path: "/contents",
  //   // icon: getIcon("material-symbols:chart-data-outline"),
  //   icon: getImage(contents),
  //   dark_icon:getImage(dashboard)

  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon('eva:lock-fill'),
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon('eva:person-add-fill'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon('eva:alert-triangle-fill'),
  // },
];

export default navConfig;
