import * as React from 'react';
import { InputLabel, Typography, useAutocomplete } from '@mui/material';
import { styled } from '@mui/system';
import { useEffect, useCallback, useState } from 'react';
import _ from "lodash";

const Label = styled('label')({
  display: 'block',
});

const Input = styled('input')(({ theme }) => ({
  width: 200,
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#000',
  color: theme.palette.mode === 'light' ? '#000' : '#fff',
}));

const Listbox = styled('ul')(({ theme }) => ({
  width: 200,
  margin: 0,
  padding: 0,
  zIndex: 1,
  position: 'absolute',
  listStyle: 'none',
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#000',
  overflow: 'auto',
  maxHeight: 200,
  border: '1px solid rgba(0,0,0,.25)',
  '& li.Mui-focused': {
    backgroundColor: '#4a8df6',
    color: 'white',
    cursor: 'pointer',
  },
  '& li:active': {
    backgroundColor: '#2977f5',
    color: 'white',
  },
}));

export default function SelectWithInput({
  label,
  placeholder,
  value,
  defaultValue,
  onChange,
  inputAdornments,
  width,
  options,
  name,
  errors,
  validation,
  wrapperSx,
  isDisabled,
  mode,
  formData,
  filterBasedOnField,
  optionLabelKey,
  customeonchange
}) {
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: 'use-autocomplete-demo',
    options: options,
    getOptionLabel: (option) => option[optionLabelKey],
  });

  const [selectOptions, setSelectOptions] = useState([]);
  const onSelectChange = (event) => {
    const value1 = event.target ?event.target.value :event
    if(customeonchange){
      customeonchange(value1)
    }
      return onChange(name,value1);
  };

  const getOptions = useCallback(() => {
    if (
      _.isEmpty(filterBasedOnField) ||
      _.isEmpty(formData) ||
      !formData[filterBasedOnField]
    ) {
      return options;
    }

    return options.filter(
      (option) => option[filterBasedOnField] === formData[filterBasedOnField]
    );
  }, [filterBasedOnField, formData, options]);

const DropDownClick = (option) => {
    // e.stopPropagation();
   onSelectChange(option.target.textContent)
}

  useEffect(() => {
    const newOptions = getOptions();
    setSelectOptions(newOptions);
  }, [getOptions]);

  return (
    <div>
      <div {...getRootProps()}>
       
      <InputLabel htmlFor={name} component="div" sx={{ fontWeight: 600, fontSize: 16, color: '#000', error: errors?.status }}>
        {label}
        {validation?.required && <Typography component="span" sx={{ color: 'red', marginLeft: '4px' }}>*</Typography>}
      </InputLabel> 
        
       
        
        <Input {...getInputProps()}   placeholder = 'Select category'
        sx={{
          width: width || "auto",
           height : '43px',
           border: "1px solid #ccc",
           px : 2,
           fontWeight : '500',
           fontFamily : 'Urbanist !important',
           fontSize : '16px'

          }
         }
        value={value}
        name={name}
         onInput={onSelectChange}
         />
      </div>
      {groupedOptions.length > 0 ? (
        <Listbox {...getListboxProps()}  sx={{
            width: '290px',
             height : '170px',
             border: "1px solid #ccc",
             fontWeight : '500',
             fontFamily : 'Urbanist !important'
            
            }
           }
        //    onClick = {(e)=>DropDownClick(e)}
           >
          {groupedOptions.map((option, index) => {
            return <li 
            style={{ padding: 5 ,cursor : 'pointer'}}
            onClick={(e) => {
                
                const optionProps = getOptionProps({ option, index });
                optionProps.onClick(e); // Manually trigger the onClick from optionProps
                DropDownClick(e, option);
            }}
        >{option[optionLabelKey]}</li>
          })}
        </Listbox>
      ) : null}
    </div>
  );
}
