import { uploadFile } from 'react-s3';

export const S3ImageUpload =async (file) => {

    window.Buffer = window.Buffer || require("buffer").Buffer;

    const config = {
        bucketName:'pnpadmin',
        dirName: 'images',
        region: 'eu-north-1',
        accessKeyId: process.env.REACT_APP_S3_ACCESSKEY,
        secretAccessKey: process.env.REACT_APP_S3_SECRETKEY
    }
    try {
      const result =  await uploadFile(file, config)
            .then(data => {
              return data;
            })
      return result
      } catch (error) {
        console.log("Error getting download URL:", error);
      }
}
