import { Navigate, Outlet } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import auth_background from "../../images/auth_background.png";
import Pnplogo from "../../images/pnplogo.png";
import { Box, Paper } from "@mui/material";
import { useSelector } from "react-redux";
import _ from "lodash";
// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  display: "block",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100%",
  overflow: "hidden",
  backgroundSize:'100% 100%',
  backgroundImage: `url(${auth_background})`,
//  backgroundPosition:"",
  backgroundRepeat: "no-repeat",
}));

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 2,
  display: "flex",
  overflow: "auto",
  justifyContent: "center",
  marginTop:10
}));

// ----------------------------------------------------------------------

export default function AuthLayout() {
  const { isLoading } = useSelector((state) => state);
  const token = localStorage.getItem('token')

  const getCookie = (name) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
      return null;
  };

  return (
    <RootStyle>
    <img src={Pnplogo} alt="icon" style={{width:'150px', marginLeft:'30px', marginTop:'25px'}}/>
      <MainStyle>
        <Paper
          sx={{
            // width: 500,
            backgroundColor:'#fff',
            height: "auto",
            minHeight: 430,
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            p: 3,
            borderRadius:'12px'
          }}
          square
        >
          {(_.isEmpty(getCookie('token')) ) &&
          !isLoading ? (
            <Outlet />
          ) : (
            <Navigate to="/" />
          )}
          {/* <Outlet/> */}
        </Paper>
      </MainStyle>
    </RootStyle>
  );
}
