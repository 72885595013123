import {
  Box,
  TextField,
  Typography,
  InputAdornment,
  InputLabel,
  FormHelperText,
  Grid,
} from "@mui/material";

import lockicon from "../../../images/lockIcon.png";


import _ from "lodash";
import { useEffect, useState } from "react";
import { customAlphabet } from "nanoid";
import CurrencyInput from "./CurrencyInput";
import { BorderAllRounded } from "@mui/icons-material";
const nanoid = customAlphabet("1234567890ABCDEFGHIJKLMNOPQRSTUZVWXYZ", 6);

export const TransTextInput = ({
  label,
  placeholder,
  value,
  onChange,
  inputAdornments,
  width,
  height,
  sx,
  customsx,
  wrapperSx,
  type,
  name,
  validation,
  errors,
  key,
  isDisabled,
  mode,
  max,
  autoGenerate,
  customeonchange
}) => {

  useEffect(() => {
    if (autoGenerate && !value && mode === "new") {
      onChange(name, nanoid());
    }
  }, [autoGenerate, mode, name, onChange, value]);

  const onTextChange = (e) => {
    if(customeonchange){
      customeonchange(e.target.value)
    }
    return onChange(name, e.target.value);
  };
  const [eyeOpen,seteyeOpen] = useState(false)
  const [customType,setcustomType] = useState(type)

  const typeChange = () => {
    seteyeOpen(!eyeOpen)
    setcustomType(type=='password'?'text' : 'password')
  }

  return (
    <Box sx={{ ...wrapperSx }} key={key}>
      <InputLabel htmlFor={name} component="div" sx={{ fontWeight: 600, fontSize: 16, color: '#000',error: errors?.status }}>
        {label}
        {validation?.required && <Typography component="span" sx={{ color: 'red', marginLeft: '4px' }}>*</Typography>}
      </InputLabel>
      <TextField
        type={customType}
        variant="outlined"
        disabled={isDisabled || mode === "view"}
        placeholder={placeholder}
        value={value}
        onChange={onTextChange}
        error={errors?.status}
        sx={{ backgroundColor: isDisabled ? '#E2E8F0' : 'intial', width: width || "auto", height, ...sx }}
        InputProps={{
          sx: customsx ? customsx : { borderRadius: '4px' },
          endAdornment: inputAdornments?.end && (
            <InputAdornment position="start" sx={{ color: "#111111" }}>
              {inputAdornments?.end}
            </InputAdornment>
          ),
          endAdornment: inputAdornments?.end && (
            <InputAdornment position="end" onClick={()=>{
              type=='password' && typeChange() 
             }}>

              <Typography color="#111111">{inputAdornments?.end}</Typography>
            </InputAdornment>
          ),

        }}
      />

      {errors?.status && (
        <FormHelperText error={errors.status}>
          {_.capitalize(errors?.message)}
        </FormHelperText>
      )}
    </Box>
  );
};


