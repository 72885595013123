import { Outlet } from "react-router-dom";
// material
import { Box, Container } from "@mui/material";
// components
import Page from "../components/common/Page";
//import ReportsList from '../components/Reports/ReportsList';

export default function GeneralLayout({ title }) {

  return (
    <>
      <Page >
        <Box sx={{ backgroundColor: '#fff', p: '0 !important', borderRadius: '3px' }} >
          <Outlet />
        </Box>
      </Page>
    </>

  );




}
