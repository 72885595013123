import { Box } from "@mui/material";
export const WrapperComponent = ({
  className,
  children,
  generateForm,
  keyValue,
  sx,
}) => {
  return (
    <Box className={className} sx={sx} key={keyValue}>
      {children?.length > 0 &&
        children.map((child, index) => generateForm(child, index))}
    </Box>
  );
};
