import { Box, Button, Grid, Stack } from "@mui/material";
import { TransForm } from "../common/TransForm/Index";
import leagueforminput from "../common/jsons/formConfigs/LeaguesForminput";
import leagueDetailsFormInput from '../common/jsons/formConfigs/LeagueDeatsilsForminput'
import { PageTitle } from "../common/PageTitle";
import Divider from '@mui/material/Divider';
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { useEffect, useState } from "react";
import { base_url } from "../backendurl/url";
import Axios from "axios";
import { Notification } from "../common/Notification";
import { CircularProgress } from "@material-ui/core";
import { Config } from "../../utils/commonFuctions/Token";
import { RefreshToken } from "../../utils/commonFuctions/RefreshToken";
import { FirebaseImageUpload } from "../common/FirebaseImageUpload";
import { S3ImageUpload } from "../common/S3ImageUpload";
import dayjs from 'dayjs';

export const LeaguesForm = ({ mode }) => {
  const [leagues, setLeaguesData] = useState([])
  const navigate = useNavigate();
  const [seassionYear, setSeassionYear] = useState([])
  const [Leagueinfo, setLeagueInfo] = useState({})


  const [seasson, setSeasson] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [notification, setnotification] = useState(false)
  const [isSystemLeagueSelected, setIsSystemLeagueSelected] = useState(false)
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      let leagueLogo = leagues.find(item=>item?.id == data?.league)

      let Leagueinfos = {
        league: data?.league,
        leaguename: data?.name,
        display_name: data?.name,
        season_year: data?.season_year,
        logo:leagueLogo?.logo
      }


      const response = await Axios.post(`${base_url}/api/pot/seasons`, Leagueinfos, Config);
      
      if (response?.status === 203) {
        setErrorMessage('League in this Season is Already Exist');
        setnotification(true);
      } else if (response?.status === 200) {
        let leaquesdata = response?.data?.data
        leaquesdata.start_date = dayjs(leaquesdata.start_date)
        leaquesdata.end_date = dayjs(leaquesdata.end_date)
        setLeagueInfo(leaquesdata);
        setnotification(false);
      }
    } 
    catch (error) {
      RefreshToken(error,setErrorMessage,setnotification) 
      console.error('Error submitting data:', error);
    } 
    finally {
      setIsLoading(false);
    }

  };


  const onleagueSubmit = async (data, name) => {
    try{
      if (name == 'fetch_ixtures') {
        navigate('autofixture', { state: data })
      } else {
        delete data.fetch_ixtures
        delete data.manually_add_fixtures
        data.standings = {}
        data.status = 'Active'
        data.display_name = seasson?.name
        data.leaguename = seasson?.name
        data.league = seasson?.league
        data.season_year = seasson?.season_year

        if(base_url == process.env.REACT_APP_DEV || base_url == process.env.REACT_APP_TEST){
          const imageUrl =  await  FirebaseImageUpload(data?.league_logo)
          data.logo = imageUrl.split('&')[0]
        }
        
        if(base_url == process.env.REACT_APP_AWS_PROD){
          const imageUrl = await S3ImageUpload(data?.league_logo)
          data.logo = imageUrl.location
        }
        
        const response = await Axios.post(`${base_url}/api/pot/seasons`, data, Config)
        if (response?.status == 203) {
          setErrorMessage('League in this Season is Aleardy Exist')
          setnotification(true)
        }
        if (response?.status == 200) {
          navigate('manualfixture', { state: response?.data?.data })
          setnotification(false)
        }
      }
    }
catch(error){
  RefreshToken(error,setErrorMessage,setnotification) 
}

  }

  const getLeagues = async () => {

    try{
      const response = await Axios.get(`${base_url}/api/pot/configureleagues?status=true`, Config)
      let leaquename = response?.data?.data.map(item => {
        return {
          id: item?.id,
          name: item?.name,
          ...item,
        }
      })
      setLeaguesData(leaquename)
      const date = new Date().getFullYear()
      let years = []
      for (let i = -1; i < 2; i++) {
        years.push({ id: date + i, name: date + i })
      }
      setSeassionYear(years)
    }
    catch(error){
      RefreshToken(error,setErrorMessage,setnotification) 
    }
  }

  useEffect(() => {
    getLeagues();
  }, []);

  const handleCancel = async () => {
    navigate(-1);
  }

  const getFormData = (newFormData) => {
    let filterdata = leagues?.find(item => item.id == newFormData.league)
    setSeasson(newFormData);
    setIsSystemLeagueSelected(filterdata?.system_league)
  }

  return (
    <Box  >
      <Box sx={{ px: 6, py: 3 }} alignContent="center">
        <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} alignContent="center">
          <PageTitle pageTitle={'Add New League'} />
          <Button sx={{ backgroundColor: '#E2E8F0', color: '#1C2434', width: '110px' }} onClick={handleCancel} >Cancel</Button>
        </Stack>
      </Box>
      <Divider sx={{ mb: 2, height: 4 }} />
      <Box style={{ position: 'relative' }}>
        {isLoading && (
          <Box
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 9999,
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <Box>
          <TransForm
            mode={mode}
            data={seasson}
            getFormData={getFormData}
            formInputs={[
              ...leagueforminput({
                leagues,
                seassionYear,
                isSystemLeagueSelected,
              }),
            ]}
            submitAction={onSubmit}
          />
          <TransForm
            submitAction={onleagueSubmit}
            data={Leagueinfo}
            mode={mode}
            formInputs={[
              ...leagueDetailsFormInput({
                leagues,
                seassionYear,
                isSystemLeagueSelected,
              }),
            ]}
          />
          <Notification
            message={errorMessage}
            setopen={setnotification}
            type={'error'}
            show={notification}
          />
        </Box>
      </Box>
      <Notification message={errorMessage} setopen={setnotification} type={'error'} show={notification} />

    </Box >
  );
};
