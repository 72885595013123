import React from 'react';
import { Box, Typography, InputLabel, Stack } from '@mui/material';
import currencyCodes from 'currency-codes';
import { TransTextInput } from './TransTextInput';
import { currencyformater } from './currencyformater';
import { PnpSelect } from './PnpSelect';

const CurrencyInput = ({
  name,
  label,
  countryCode,
  value,
  onChange,
  placeholder,
  currencyDefaultValue,
  errors,
  wrapperSx,
  isDisabled,
  selectDisable,
  mode,
  filterBasedOnField,
  optionLabelKey,
  width,
  height,
  cardlength,
  sx,
  inputAdornments,
  validation,
}) => {
  let formattedValue 
  if(countryCode){
    const numericValue = value ? typeof value === 'string' ? parseFloat(value.replace(/,/g, '')) : value : '';
    formattedValue = currencyformater(numericValue, countryCode);
  }
const currenyWidth = {
  twoCard : {xl:'550px',lg:'330px'},
  threeCard : {xl:'490px',lg:'206px',}
}


  const currency_code = currencyCodes.data.map((item) => { return {'id':item.code,'name':item.code} })
  
  const oninputchange = (key, value) => {
    return onChange(key, value)
  }

  return (
    <Box sx={{width:width, ...wrapperSx }}  >
      <InputLabel htmlFor={name} component="div" sx={{ fontWeight: 600, fontSize: 16, color: '#000', error: errors?.status }}>
        {label}
        {validation?.required && <Typography component="span" sx={{ color: 'red', marginLeft: '4px' }}>*</Typography>}
      </InputLabel>  
      <Stack direction="row" justifyContent={'left'} sx={{width:'100%'}}  >
        <PnpSelect
          value={countryCode}
          onChange={oninputchange}
          options={currency_code}
          defaultValue={currencyDefaultValue || ""}
          isDisabled={selectDisable ? selectDisable : isDisabled}
          mode={mode}
          filterBasedOnField={filterBasedOnField}
          optionLabelKey={optionLabelKey}
          customsx={{backgroundColor: '#E2E8F0', color: '#64748B', borderRadius:' 4px 0 0 4px ' }}
          name={'pot_currency'}
          width={'110px'}
          className={"selectclass"}
        />
        <TransTextInput
          type='text'
          value={formattedValue}
          onChange={oninputchange}
          placeholder={placeholder}
          isDisabled={isDisabled}
          mode={mode}
          name={name}
          fullWidth
          inputAdornments={inputAdornments}
          customsx={{borderRadius:'0 4px 4px 0',width:currenyWidth[cardlength]}}
          sx={{ width: '100%', height,   ...sx  }}
        />
      </Stack>
      {errors && (
        <Box sx={{ color: 'error.main' }}>
            <span>{errors?.message}</span>
        </Box>
      )}
    </Box>
  );
};

export default CurrencyInput;
