// routes
import AppRouter from "./AppRoutes";
import AuthRouter from "./AuthRouter";
// theme
import ThemeProvider from "./theme";
// components
import React, { useEffect } from "react";
import { CircularProgress, Box } from "@material-ui/core";
// import { auth } from "./firebase/FirebaseInit";
import { Link, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux";

import _ from "lodash";

// ----------------------------------------------------------------------

export default function App() {
  const { isLoading } = useSelector((state) => state);

  return (
    <ThemeProvider>
      {isLoading && (
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      )}
      {/* <Notification /> */}
      <AppRouter />
      <AuthRouter />
    </ThemeProvider>
  );
}
