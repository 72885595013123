import {
  Box,
  Select,
  Typography,
  InputAdornment,
  InputLabel,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';


export const PnpSelect = ({
  label,
  placeholder,
  value,
  defaultValue,
  onChange,
  width,
  options,
  name,
  errors,
  validation,
  wrapperSx,
  isDisabled,
  mode,
  customeonchange,
  customsx
}) => {

  const onSelectChange = (event, newValue) => {
    if (newValue) {
      if(customeonchange){
        customeonchange(newValue.id)
      }
      return onChange(name, newValue.id);
    }
  };
  return (
    <Box sx={wrapperSx}>
      <InputLabel htmlFor={name} component="div" sx={{ fontWeight: 600, fontSize: 16, color: '#000', error: errors?.status }}>
        {label}
        {validation?.required && <Typography component="span" sx={{ color: 'red', marginLeft: '4px' }}>*</Typography>}
      </InputLabel>      

      <Autocomplete
        disablePortal
        id="combo-box-demo"
        value={options.find((option) => option.id === value)}
        // value={value =='' ? null:options.find((option) => option.id === value)}
        options={options}
        getOptionLabel={(option) => option.name.toString()}
        onChange={onSelectChange}
        sx={{
          width: width || "auto",
          "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
            height: '100%',
            padding: 0
          },
          pointerEvents: isDisabled || mode === "view" ? "none" : "all",
          ...customsx
        }}
        renderInput={(params) => <TextField {...params}

        />}
      />
      {errors?.status && (
        <FormHelperText error={errors.status}>
          {_.capitalize(errors?.message)}
        </FormHelperText>
      )}
    </Box>
  );
};