import { Box, Divider } from "@mui/material";
import { TransForm } from "../common/TransForm/Index";
import UserFormInputs from "../common/jsons/formConfigs/userFormConfig";
import { PageTitle } from "../common/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { useCallback, useEffect } from "react";
import axios from "axios";
import { base_url } from '../backendurl/url'
import { useState } from "react";
import ModalPopUp from "../common/TransForm/ModalPopUp";
import { Config } from "../../utils/commonFuctions/Token";
import { RefreshToken } from "../../utils/commonFuctions/RefreshToken";
import { Notification } from "../common/Notification";

export const UserForm = ({ mode }) => {
  const { selectedUser } = useSelector(
    (state) => state
  );

  const [popup, setPopup] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user_uuid } = useParams("user_uuid");
  const [loading, setLoading] = useState(false)
  const [notification, setnotification] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [userData, setUserdata] = useState()

  const [saveBtnText, setSaveBtnText] = useState({
    title: 'Do you want to Add New Admin ?',
    content: 'Are you sure, you want to create & save a new Admin with given details.',
    btnText: 'yes , Save'
  })

  const onSubmit = async (data) => {

    if (mode === "edit") {
      setSaveBtnText({
        title: 'Save Changes ?',
        content: 'Are you sure, you want to save the changes made on Admin',
        btnText: 'yes , Save'
      })
      setUserdata(data)
      setPopup(true)


    } else if (mode === "view") {
      navigate(`/users/edit/${user_uuid}`);
    } else {

      setSaveBtnText({
        title: 'Confirm the action ?',
        content: 'Are you sure, you want to Add New Admin',
        btnText: 'yes , Save'
      })
      setUserdata(data)
      setPopup(true)
    }
  };


  const SubmitUser = async () => {
    try{
      if (mode == 'edit') {
        const response = await axios.put(`${base_url}/api/accounts/user/${user_uuid}`, userData, Config)
        if (response?.data?.status == 'Success') {
          navigate(`/users`);
        }
      } else {
        delete userData?.Add
        delete userData?.id
        delete userData?.is_active
        const response = await axios.post(`${base_url}/api/accounts/user/create`, userData, Config)
        if (response?.data?.status == 'Success') {
          navigate(`/users`);
        }
      }
  
  
      setPopup(false)
    }
   catch(error){
    RefreshToken(error,setErrorMessage,setnotification)
   }

  }

  const getSelectedUser = useCallback(async () => {
    try{
      if (mode != 'new') {
        setLoading(true)
        const response = await axios.get(`${base_url}/api/accounts/user/${user_uuid}`, Config);
  
        if (response?.status == 200) {
  
          delete response?.data?.id
          setUserdata(response?.data)
        }
  
        setLoading(false)
      }
    }
   
catch(error){
  RefreshToken(error,setErrorMessage,setnotification)
}
  }, [selectedUser, user_uuid]);

  useEffect(() => {
    getSelectedUser();
  }, []);


  
  return (
    <Box>
      <Box display="flex" sx={{ px: 6, pt: 3 }} alignContent="center">
        <PageTitle pageTitle={mode == 'new' ? 'Add New Admin' : 'Admin Details'} backBtn />
      </Box>

      <TransForm
        submitAction={onSubmit}
        data={userData}
        mode={mode}
        formInputs={UserFormInputs({ mode })}
      />

      <ModalPopUp
        openpopup={popup}
        setPopup={setPopup}
        title={saveBtnText.title}
        subTitle={saveBtnText.content}
        onClick={SubmitUser}
        save={saveBtnText.btnText}
        cancel={'Exit'}
      />
    <Notification message={errorMessage} setopen={setnotification} type={'error'} show={notification} />

    </Box>
  );
};
