import { Navigate, useRoutes } from "react-router-dom";
import AuthLayout from "./layouts/auth";
import Login from './components/Auth/LoginPage.js/Login'
import ForgotPassword from "./components/Auth/ForgotPassword";
import VerifyEmail from "./components/Auth/VerifyEmail";
import VerifyOTP from "./components/Auth/VerifyOTP";
// ----------------------------------------------------------------------

export default function AuthRouter() {
  return useRoutes([
    {
      path: "auth",
      element: <AuthLayout />,
      children: [
        // {
        //   path: "",
        //   element:
        //     !_.isEmpty(userInfo) && userInfo?.status === "new" ? (
        //       <Navigate to="verifyEmail" />
        //     ) : !_.isEmpty(userInfo) &&
        //       userInfo?.status === "email_verified" ? (
        //       <Navigate to="updateNewPassword" />
        //     ) : !_.isEmpty(userInfo)  ? (
        //       <Navigate to="/" />
        //     ) : (
        //       <Navigate to="login" />
        //     ),
        // },
        {
          path: "login",
          element: <Login />,
        },
        {
          path: "forgotpassword",
          element: <VerifyEmail />,
        },
        {
          path: "verifyEmail",
          element: <VerifyEmail />,
        },
        {
          path: "verifyOtp",
          element: <VerifyOTP />,
        },
        {
          path: "updateNewPassword",
          element: <ForgotPassword mode="update_password" />,
        },
        {
          path: "forgotpassword",
          element: <ForgotPassword mode="reset_password" />,
        },
      ],
    },
  ]);
}
