import { Height } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import React from 'react'

export default function CardComponent({img,amount,text}) {
  return (
    <Box sx={{display:'flex',backgroundColor:'#fff',padding:'8px',px:3,gap:2}}>
        <Box>
        <img sx={{width:'60px', height:'60px'}} src={img} alt='dashboard Img'/>
        </Box>
       <Box sx={{display:'flex',flexDirection:'column',font:'Urbanist'}}>
        <Typography sx={{fontSize:'25px',fontWeight:'600'}}>{amount}</Typography>
        <Typography sx={{fontSize:'14px'}}>{text}</Typography>
        </Box>

    </Box>
  )
}
