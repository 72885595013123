import axios, { Axios } from "axios";
import { base_url } from "../../components/backendurl/url";
import { Config } from "./Token";

const d = {
  message: "Request failed with status code 401",
  name: "AxiosError",
  config: {
    transitional: {
      silentJSONParsing: true,
      forcedJSONParsing: true,
      clarifyTimeoutError: false,
    },
    transformRequest: [null],
    transformResponse: [null],
    timeout: 0,
    xsrfCookieName: "XSRF-TOKEN",
    xsrfHeaderName: "X-XSRF-TOKEN",
    maxContentLength: -1,
    maxBodyLength: -1,
    env: { FormData: null },
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization:
        "Bearer eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzE4MjcyMDM0LCJpYXQiOjE3MTgyNzAxNjYsImp0aSI6ImE4NTE1M2VmY2I1NTRjNWJiMWMwMGE3MWVjM2VhOTBjIiwidXNlcl9pZCI6ImQ1NTg3Zjc2LWU0OGUtNGM4ZS04ZTk1LTI4ZTExMmExMWI5ZiIsInVzZXJuYW1lIjoicG5wYWRtaW4iLCJpc19hZG1pbiI6dHJ1ZSwiaWQiOiJkNTU4N2Y3Ni1lNDhlLTRjOGUtOGU5NS0yOGUxMTJhMTFiOWYifQ.Dh9H4qSohLyNMgSTb8aeokHT2kVb5z79kPx5V98WJgM",
    },
    method: "get",
    url: "https://pnp-backend-faa9w.ondigitalocean.app/api/pot/configureleagues?status=true",
  },
  code: "ERR_BAD_REQUEST",
  status: 401,
};
export const RefreshToken = async (error, msgState, notificationState) => {

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };
  try {
  if (error?.response?.request?.status == 401) {
    let response = await axios.post(`${base_url}/api/accounts/token/refresh/`, {
      refresh: getCookie('refresh'),
    });
    // localStorage.setItem("refresh", response?.data?.refresh);
    // localStorage.setItem("token", response?.data?.access);
    document.cookie = `token=${response?.data?.access || ""}; path=/; SameSite=None; Secure`;
    document.cookie = `refresh=${response?.data?.refresh || ""}; path=/; SameSite=None; Secure`;
  
    const user = await axios.get(`${base_url}/api/accounts/user/create?type=player`, {headers: {
      "Authorization": 'Bearer ' + response?.data?.access
    }
  });
    window.location.reload();
    
  } else if (error?.response?.request?.status == 400) {
  let message =   Object.keys(error?.response.data).map(item=>{
      return `${item} : ${error?.response.data[item][0]} `
    }).join("")
    msgState(message)
    notificationState(true);

  } else {
    msgState(error.message?error.message:"Can't make a request" );
    notificationState(true);
  }
  }
  catch (err) {
    localStorage.clear()
    window.location.pathname = "/auth/login";
  }
};
