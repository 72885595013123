let currencyShort = [
  {
    s_code: "en-IN",
    c_code: "INR",
  },
  {
    s_code: "en-AE",
    c_code: "AED",
  },
];
 
export function currencyformater(amount, currencyCode) {
  const res = currencyShort.filter((currency) => {
    if (currencyCode === currency?.c_code) {
      return currency;
    }
  });
  const formatter = new Intl.NumberFormat(res.length ? res[0].s_code:"en-AE", {
    // style: "currency",
    currency: currencyCode,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  return amount ? formatter.format(amount): amount
}