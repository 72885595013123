// App.js
import React, { useEffect, useState } from 'react';
import EditableTableGrid from '../common/TransForm/PnpDataGridEdit';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { PageTitle } from '../common/PageTitle';
import { useLocation, useNavigate } from 'react-router-dom';
import { utcTimetoLocalTimeWithzone } from '../common/TransForm/Localdateformater';
import axios from 'axios';
import { base_url } from '../backendurl/url';
import { Config } from '../../utils/commonFuctions/Token';
import { RefreshToken } from '../../utils/commonFuctions/RefreshToken';
import { Notification } from '../common/Notification';

export const Manualfixtures = () => {
  const navigate = useNavigate()
  const location = useLocation();
  let { state } = location;

  const [matchWeekOpt, setMatchweekOpt] = useState([])
  const [teamsOtp, setTeamsOpt] = useState([])
  const [VenueOpt, setVenueOpt] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [notification,setnotification] = useState(false)
  const [jsonData, setJsonData] = useState([]);


  const headers = [
    {
      name: 'match_week', lable:'Match Week', type: 'select', options: matchWeekOpt, show: true, valueRenderer: (data) => {
        return (
          data['match_week']
        );
      },
    },
    {
      name: 'hometeam', lable:'Home Team', type: 'select', options: teamsOtp.map(item=>item.name),optionJson:teamsOtp, show: true, valueRenderer: (data) => {
        return (
          data['hometeam']        
        );
      },
    },
    {
      name: 'awayteam', lable:'Away Team', type: 'select', options: teamsOtp.map(item=>item.name),optionJson:teamsOtp, show: true, valueRenderer: (data) => {
        return (
          data['awayteam']
        );
      },
    },
    {
      name: 'venue', lable:'Venue', type: 'text', show: true, valueRenderer: (data) => {
        return (
          data['venue']
        );
      },
    },
    {
      name: 'start', lable:'Match Date', type: 'date', show: true, valueRenderer: (data) => {
        let date = utcTimetoLocalTimeWithzone(data["start"])
        const [datePart, timePart] = date.split(', ');
        return (
          <Typography> {datePart} </Typography>
        );
      },
    },
    {
      name: 'time', lable:'Start Time', type: 'time', show: true,
      valueRenderer: (data) => {
      
        let date = utcTimetoLocalTimeWithzone(data["start"])
        const [datePart, timePart] = date.split(', ');

        return (
          <Typography> {timePart} </Typography>
        );
      },
    },

  ];



  const handleUpdateData = (updatedData) => {

    updatedData?.map(item => {
        item.leagues_season =  state?.id
        item.status = 'NS'
        item.result = 'None'
        item.fixture_id = ''
        item.awayteam_goal = 0
        item.hometeam_goal = 0
    })

    setJsonData(updatedData);

  };
  const editableColumns = [0, 1, 2, 3, 4, 5]

  const gotoSave = async () => {
    try{
      jsonData?.map(item => {
        delete item.time
      })
      const response = await axios.post(`${base_url}/api/pot/fixtures`, jsonData, Config)
      if(response?.status == 200){
        navigate('/leagues')
      }
    }
    catch(error){
      RefreshToken(error,setErrorMessage,setnotification)  
    }
  };


  const gotoclose = () => {
    navigate(-1);
  };

  const getDropdown =async () => {
    try{
      const matchweekotpResponese =  await axios.get(`${base_url}/api/pot/matchweek?league=${state.league}`, Config);
      matchweekotpResponese?.data?.data?.map(item => {
        matchWeekOpt.push(item.match_week_name)
      
      })
      const teamotpResponese =  await axios.get(`${base_url}/api/pot/teams?league=${state.league}`, Config);
      
      teamotpResponese?.data?.data?.map(item => {
        teamsOtp.push({ name:item?.name,logo:item?.logo})
        VenueOpt.push(item?.groundname)
       
      })
      const jsonResponese =  await axios.get(`${base_url}/api/pot/fixtures?season=${state?.season_year}&league=${state?.id}`, Config);
      if(jsonResponese?.status == 200){
        jsonResponese?.data?.data?.map(item => {
          item.time = item.start.slice(11, 16)
        })
        setJsonData(jsonResponese?.data?.data)
      }
    }
    
    catch(error){
      RefreshToken(error,setErrorMessage,setnotification)  
    }
  }

  useEffect(()=> {
    getDropdown()
  },[])


  return (
    <Box sx={{ width: '100%', typography: 'body1', }}>
      <Stack direction={'row'} sx={{ justifyContent: 'space-between', px: 6, pt: 3 }} alignContent="center">
        <PageTitle pageTitle={'Manual Fixtures'} />
        <Box>
          <Button sx={{ backgroundColor: '#3C50E0', color: '#EFF4FB', '&:hover': { backgroundColor: '#3C50E0', color: '#EFF4FB' } }} onClick={gotoSave} >Save</Button>
          <Button sx={{ backgroundColor: '#E2E8F0', color: '#1C2434', '&:hover': { backgroundColor: '#E2E8F0', color: '#1C2434' }, ml: 3 }} onClick={gotoclose} >Close</Button>
        </Box>
      </Stack>
      <Divider sx={{ my: 2, height: 4, mx: 4 }} />
      <Stack direction={'row'} sx={{ px: 6, pt: 3 }} alignContent="center">
        <Typography sx={{ color: '#64748B', fontSize: '16px', mr: 5 }}>League ID: <span style={{ color: '#08162A', fontWeight: 500 }}>{state.league} </span></Typography>
        <Typography sx={{ color: '#64748B', fontSize: '16px', mr: 5 }}>League Name: <span style={{ color: '#08162A', fontWeight: 500 }}> {state.leaguename} </span> </Typography>
        <Typography sx={{ color: '#64748B', fontSize: '16px', mr: 5 }}>Season: <span style={{ color: '#08162A', fontWeight: 500 }}> {state.season_year}</span> </Typography>

      </Stack>

      <Box sx={{ pb: 10 }}>
        <EditableTableGrid headers={headers} jsonData={jsonData} onUpdateData={handleUpdateData} showAdd={true} editableColumns={editableColumns} />
      </Box>
      <Notification  message = {errorMessage} setopen={setnotification} type ={'error'} show={notification}  />

    </Box>
  );
};

