// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            boxShadow: "none",
            backgroundColor: "secondary.lighter",
          },
          "&:Mui-disabled": {
            boxShadow: "none",
            backgroundColor: "secondary.lighter",
          },
        },
        sizeLarge: {
          height: 48,
        },
        containedInherit: {
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
          "&:hover": {
            backgroundColor: theme.palette.grey[400],
          },
        },
        containedPrimary: {
          backgroundColor: theme.palette.secondary.main,
          boxShadow: theme.customShadows.primary,
          "&:hover": {
            boxShadow: "none",
            backgroundColor: theme.palette.secondary.main,
            opacity: 0.8,
          },
          "&:Mui-disabled": {
            boxShadow: "none",
            backgroundColor: theme.palette.grey,
          },
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary,
        },
        containedError: {
          backgroundColor: theme.palette.error,
          boxShadow: theme.customShadows.primary,
        },
        outlinedInherit: {
          backgroundColor: "#fff",
          color: theme.palette.secondary.dark,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
        outlinedPrimary: {
          border: `1px solid ${theme.palette.secondary.dark}`,
          backgroundColor: "#fff",
          color: theme.palette.secondary.dark,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
        outlinedError: {
          backgroundColor: "#fff",
          borderColor: theme.palette.error,
          boxShadow: theme.customShadows.primary,
        },
        textInherit: {
          backgroundColor: "#fff",
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textPrimary: {
          backgroundColor: "transparent",
          color: theme.palette.secondary.main,
        },
      },
    },
  };
}
