import {
  Avatar,
  Box,
  Button,
  Chip,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useState, useRef } from "react";
import _ from "lodash";
import Iconify from "../Iconify";
import avatar_default from "../../../images/avatar_default.jpg";

const activeStyles = {
  backgroundColor: "primary.lighter",
  opacity: 0.8,
  borderColor: "primary.main",
};

export default function TransFileUpload({
  label,
  value,
  onChange,
  width,
  name,
  errors,
  validation,
  accept,
  sx,
  uploadType,
  type,
  mode,
  isDisabled,
  fileRestriction,
}) {
  const [dragActive, setDragActive] = useState(false);
  const [selectedFile, setSetSelectedFile] = useState(value);
  const inputRef = useRef(null);
  const [errormsg,seterrormsg] = useState('')

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      if (type === "file") {
        setSetSelectedFile(e.dataTransfer.files[0]);
        onChange(name, e.dataTransfer.files[0]);
      } else {
        setSetSelectedFile([...e.dataTransfer.files]);
        onChange(name, [...e.dataTransfer.files]);
      }
    }
  };



  const ImageSizeRestriction = (selectedFile) => {
    
    const img = new Image();
  
    const loadImage = (url) => {
      return new Promise((resolve, reject) => {
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = url;
      });
    };
  


   return loadImage(URL.createObjectURL(selectedFile))
      .then((img) => {
        if (img.width <= fileRestriction.width && img.height <= fileRestriction.height) { // Restrict to 800x600
          flag = true
          seterrormsg('')
        } else {
          flag = false
          seterrormsg(`The given image must be under ${fileRestriction.width}x${fileRestriction.height} pixels`)
         // Reset the input
        }
      })
      .catch((error) => {
        flag = false
        seterrormsg(error)
      });
  };


  var flag = true

  const handleChange = async function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      if (type === "file") {
        flag = true
if(fileRestriction){
  await ImageSizeRestriction(e.target.files[0]) 
}

if(flag){
  setSetSelectedFile(e.target.files[0]);
  onChange(name, e.target.files[0]);
}
else{
  setSetSelectedFile(null);
  onChange(name, null);
}
        
      } else {
        setSetSelectedFile([...selectedFile, ...e.target.files]);
        onChange(name, [...e.target.files]);
      }
    }
  };

  const getIsDisabled = () => {
    return mode === "view" || isDisabled;
  };

  const onButtonClick = () => {
    if (!getIsDisabled()) {
      inputRef.current.click();
    }
  };

  const handleDelete = (index) => {
    if (!getIsDisabled()) {
      setSetSelectedFile([...selectedFile.filter((f, i) => i !== index)]);
    }
  };

  if (uploadType === "inlineDocument") {
    return (
      <Box
        onClick={onButtonClick}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        sx={{ width: width }}
      >
        <Typography
          htmlFor={name}
          component={InputLabel}
          fontWeight={600}
          fontSize={16}
          color="#000"
          error={errors?.status}
          required={validation?.required}
        >
          {label} 
        </Typography>
        <input
          ref={inputRef}
          type="file"
          multiple={type === "files"}
          style={{ display: "none" }}
          onChange={handleChange}
          accept={accept}
        />
        <TextField
          // width={width}
          sx={{width:'100% !important',backgroundColor : `${isDisabled ?'#e2e8f0' : '#fff'}`}}
          placeholder={label}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <Iconify icon="uit:upload-alt" fontSize="25px" color="#000" />
              </InputAdornment>
            ),
          }}
          value={selectedFile?.name || value}
        />
        {errormsg != '' &&  <Typography
          color="red"
        >
          {errormsg}
        </Typography>}
        
      </Box>
    );
  }

  const getFileUrl = () => {
    if (typeof selectedFile === "string") {
      return selectedFile;
    }
    if (selectedFile instanceof File) {
      return URL.createObjectURL(selectedFile);
    }

    return avatar_default;
  };

  return (
    <Box >
      {uploadType === "documents" && (
        <Typography
          component={InputLabel}
          htmlFor={name}
          color="#5C5C5C"
          cursor="pointer"
          sx={{
            mt: 3,
          }}
        >
          {label}
        </Typography>
      )}
      <Box
        onClick={onButtonClick}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        width={width}
        sx={{
          height: 120,
          px: 6,
          py: 2,
          border: "1px dashed",
          borderColor: dragActive ? "primary.main" : "#D3D3D3",
          backgroundColor: dragActive ? "primary.lighter" : "#fff",
          cursor: getIsDisabled() ? "not-allowed" : "pointer",
          mb: 6,
          ...sx,
        }}
      >
        <input
          ref={inputRef}
          type="file"
          multiple={type === "files"}
          style={{ display: "none" }}
          onChange={handleChange}
          accept={accept}
          disabled = {isDisabled}
        />
        {uploadType === "documents" && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
           sx={{
            background : '"#000"'
           }}
          >
            {selectedFile.map((file, i) => (
              <Chip
                sx={{
                  borderRadius: 0,
                  mr: 1,
                  ".MuiChip-deleteIcon": {
                    color: "#FE8E65",
                  },
                }}
                deleteIcon={
                  <Iconify
                    icon="material-symbols:close"
                    sx={{ color: "#FE8E65" }}
                  />
                }
                label={file.name}
                onDelete={() => handleDelete(i)}
              />
            ))}
            <Box
              display="flex"
              justifyContent="center"
              alignContent="center"
              sx={{
                p: 2,
                height: 33,
                border: "1px solid #D3D3D3",
                flexWrap: "wrap",
                gap: 1,
              }}
            >
              <Iconify
                icon="material-symbols:cloud-upload"
                sx={{ fontSize: 23 }}
                color="#54B5FF"
              />
              <Typography>Drag & Drop Files or Browse</Typography>
            </Box>
          </Box>
        )}{" "}
        {uploadType === "profile_pic" && (
          <Box display="flex" alignItems="center">
            <Avatar
              sx={{
                width: 80,
                height: 80,
                mr: 3,
                border: "1px solid",
                borderColor: "secondary.main",
                padding: 0.5,
                backgroundColor: "#fff",
              }}
              // src={value || selectedFileURL || "/static/avatars/avatar_default.jpg"}
              alt="photoURL"
            >
              <img
                style={{ width: 75, height: 75, borderRadius: "50%" }}
                src={getFileUrl() || avatar_default}
                alt="/static/avatars/avatar_default.jpg"
              />
            </Avatar>
            <Typography
              component={InputLabel}
              htmlFor={name}
              color="#5C5C5C"
              cursor="pointer"
            >
              {label}
            </Typography>
          </Box>
        )}
      
      </Box>
     
    </Box>
  );
}
