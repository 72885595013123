import * as Yup from "yup";
import _ from "lodash";

export const typeStringInput = (type) => {
  return ["text", "textarea", "email"].includes(type);
};

let formdataas

const validFormElements = (type) => {
  return [
    "text",
    "number",
    "email",
    "select",
    "password",
    "action",
    "wrapper",
    "file",
    "files",
    "confirmPassword",
    "date",
    "currencyinput",
    "menuselect"
  ].includes(type);
};

// export const

const TypeSchema = {
  text: Yup.string(),
  password: Yup.string(),
  number: Yup.string(),
  email: Yup.string().email(),
  date: Yup.date(),
  SelectWithInput: Yup.string(),
  select: (options) => Yup.string().oneOf(options?.map((o) => o?.id?.toString())),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
  currencyinput: Yup.string(),
  file:Yup.mixed(),
  textArea: Yup.string(),
  menuselect:Yup.object().required() || Yup.string(),
};

export const initForm = (formSchema) => {
  return {
    values: getFormValues(formSchema),
    validationSchema: Yup.object().shape({
      ...getValidationSchema(formSchema),
    }),
  };

};

export const getFormValues = (formSchema) => {
  let values = {};
  formSchema?.forEach(
    ({ label, type, name, options, value, validation, children }) => {
      if (validFormElements(type)) {
        if (type === "wrapper") {
          values = { ...values, ...getFormValues(children) };
        }else if(type=='file'){
          values[name]  = value || null
        } else {
          values[name] = value || "";
        }
      }
    }
  );

  return values;
};

export const getValidationSchema = (formSchema) => {
  let validationSchema = {};
  formSchema
    .filter((f) => f.type !== "textview")
    .forEach(({ label, type, name, options, validation, children }) => {
      if (type === "wrapper") {
        validationSchema = {
          ...getValidationSchema(children),
          ...validationSchema,
        };
      } else {
        validationSchema[name] = type == 'select'
          ? TypeSchema[type](options)
          : TypeSchema[type];
      }

      if (validation?.required) {
        validationSchema[name] = validationSchema[name].required(
          `${label} can't be empty`
        );
      }
      if (validation?.minLength) {
        validationSchema[name] = validationSchema[name].min(
          validation.minLength,
          `Minimum ${validation.minLength} required`
        );
      }
      if (validation?.maxLength) {
        validationSchema[name] = validationSchema[name].max(
          validation.maxLength,
          `only ${validation.maxLength} charactors allowed`
        );
      }
      if (name == "value_of_token" || name == 'deadline_voting') {
        // Always fail validation for min_pot_value
        validationSchema[name] = validationSchema[name].min(
          1,
          "value of token must be greater than 0"
        );
      }

      
      
      // if(validation?)

      // if(name == 'password'){
      //   const passwordRegExp = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+])[A-Za-z0-9!@#$%^&*()_+]{8,12}$/
      //     validationSchema[name] = validationSchema[name].matches(
      //       passwordRegExp,
      //       "Password must be 8 to 12 characters, at least one uppercase letter, one number, and one special character"
      //     );
      // }

      // if(name == 'email'){
      //   const emailRegExp = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+])[A-Za-z0-9!@#$%^&*()_+]{8,12}$/
      //     validationSchema[name] = validationSchema[name].matches(
      //       emailRegExp,
      //       "Invalid email address"
      //     );
      // }



      if (type === "number") {
        if (name == 'adminfee' ) {
          const adminfeeRegExp = /^(99(?:\.0{1,2})?|[1-9]?\d(?:\.\d{1,2})?|0(?:\.\d{1,2})?)%?$/
          validationSchema[name] = validationSchema[name].matches(
            adminfeeRegExp,
             "Admin Fee is not valid"
          );
        }

        if ( name=='deadline_voting') {
          const adminfeeRegExp = /^(1000(?:\.0{1,2})?|\d{1,3}(?:\.\d{1,2})?|0(?:\.\d{1,2})?)%?$/

          validationSchema[name] = validationSchema[name].matches(
            adminfeeRegExp,
           'Deadline voting is not valid'
          );
        }

        if (name == 'prediction_deadline' || name == 'min_picks_for_splitting' || name == 'picks_per_player' || name == 'tokens_per_pick') {
          const padminfeeRegExp = /^\d*$/;
          validationSchema[name] = validationSchema[name].matches(
            padminfeeRegExp,
            name == "min_picks_for_splitting" ? ' Minimum Picks for splitting is not valid' : name == 'prediction_deadline' ? 'Prediction Deadline is not valid' : name == 'picks_per_player' ? 'Picks Per Player is not valid' : 'Tokens Per Pick is not valid'
          );
        }


        // if (name == 'mobile_number') {
        //   const phoneRegExp = /^[0-9]{10}$/;
        //   validationSchema[name] = validationSchema[name].matches(
        //     phoneRegExp,
        //     "Phone number is not valid"
        //   );
        // }

      }
      if (validation?.maxLength) {
        validationSchema[name] = validationSchema[name].max(
          validation.maxLength,
          `Maximum ${validation.maxLength} allowed`
        );
      }
    });

  return validationSchema;
};


export const validate = async (validationSchemaState, formData) => {
  try {
    formdataas = true;
    const parsedValues = await validationSchemaState.validate(formData, {
      abortEarly: false,
    });

    return parsedValues;
  } catch (err) {
    throw err;
  }
};

export const getIsDisabled = (formData, element, mode, isSubmitting) => {
  const { validation } = element;
  let applicableRulesToDisable = [];
  validation?.disabledRules?.forEach((rule) => {

    const { basedOnValue, basedOnMode, comparator, compareValue, otherField } =
      rule;
    const currentValue = basedOnMode ? basedOnMode : formData[basedOnValue];
    const otherValue = basedOnMode
      ? mode
      : otherField
        ? formData[otherField]
        : compareValue;
    switch (comparator) {
      case "eq":
        if (otherValue === currentValue) {
          applicableRulesToDisable.push(rule);
        }
        break;

      case "in":
        if (otherValue.includes(currentValue)) {
          applicableRulesToDisable.push(rule);
        }
        break;

      default:
        break;
    }
  });

  return (
    !_.isEmpty(applicableRulesToDisable) ||
    (element.type === "action" && isSubmitting)
  );
};
