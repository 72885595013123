import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Config } from "../../utils/commonFuctions/Token";

// export const base_url= process.env.REACT_APP_LOCAL

//test
// export const base_url= process.env.REACT_APP_UAT
// 

//prod
// export const base_url ='https://api.picksnprizes.com';

//dev
// export const base_url = process.env.REACT_APP_DEV;

//tets url not available
// export const base_url = process.env.REACT_APP_TEST

//uat
export const base_url= process.env.REACT_APP_AWS_LOCAL

const Navigate = () => {
    window.location.pathname = "/auth/login"
    localStorage.clear()
};


export const GetPotConfigData = async () => {
    try{
        const response = await axios.get(
            `${base_url}/api/pot/potconfigs?default=true`,
            Config
          );
          if (response?.status == 200) {
            return response.data;
          }
          return null
    } catch(errror){
        Navigate()
    }
  };
  

export const PostPotConfigData = async (consfigData) => {
  const response = await axios.post(
    `${base_url}/api/pot/potconfigs`,
    consfigData,
    Config
  );
  if (response?.status == 200) {
    return response.data;
  } else if (response?.status == 403) {
    Navigate("/login");
  }
};

export const PutPotConfigData = async (consfigid, consfigData) => {
  const response = await axios.put(
    `${base_url}/api/pot/potconfigs/${consfigid}`,
    consfigData,
    Config
  );
  if (response?.status == 200) {
    return response.data;
  } else if (response?.status == 403) {
    Navigate("/login");
  }
};


export const GetConfigLeague = async () => {
    const response = await axios.get(`${base_url}/api/pot/configureleagues`, Config);

    if (response?.status == 200) {
      return response.data;
    } else if (response?.status == 403) {
      Navigate("/login");
    }
  };

  export const PatchConfigLeague = async (leagueId, leaguedetails) => {
    const response = await axios.patch(`${base_url}/api/pot/configureleagues/${leagueId}`, leaguedetails, Config);

    if (response?.status == 200) {
      return response.data;
    } else if (response?.status == 403) {
      Navigate("/login");
    }
  };