import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
} from "react-router-dom";
// material
import { alpha, useTheme, styled } from "@mui/material/styles";
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";
//
import Iconify from "./Iconify";
import Axios from "axios";
import { base_url } from "../backendurl/url";
import { Config } from "../../utils/commonFuctions/Token";
// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  fontSize: '18px',
  color: theme.palette.primary.main,
  marginLeft: 6
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: "inherit",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  paddingRight: 12
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active, showText, permissions }) {

  const theme = useTheme();

  const isActiveRoot = active(item.path);

  const { title, path, icon, dark_icon, info, children } = item;

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return JSON.parse(parts.pop().split(';').shift());
    return null;
  };

  const activeRootStyle = {
    color: "#fff",
    fontWeight: "500",
    bgcolor: '#1E304B',
  };

  const activeSubStyle = {
    color: "#fff",
    fontWeight: "500",
  };
  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            color: '#717C90',
            "&:hover": {
              backgroundColor: "transparent", // Set background color to transparent on hover
              color: "#FFFFFF", // Use the inherit color on hover
            },
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle sx={{ color: '#717C90' }}>{isActiveRoot ? dark_icon : icon}</ListItemIconStyle>
          {!showText ? <ListItemText sx={{ color: isActiveRoot ? '#fff' : '#717C90' }} disableTypography primary={title} /> : ''}
          {info && info}
          <Iconify
            icon={
              open
                ? "eva:arrow-ios-downward-fill"
                : "eva:arrow-ios-forward-fill"
            }
            type='icon'
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children?.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              return (

                getCookie('is_superuser') ?
                  <ListItemStyle
                    key={title}
                    component={RouterLink}
                    to={path}
                    sx={{
                      color: '#717C90',
                      "&:hover": {
                        backgroundColor: "transparent", // Set background color to transparent on hover
                        color: "#FFFFFF", // Use the inherit color on hover
                      },
                      ...(isActiveSub && activeSubStyle),
                    }}
                  >
                    <ListItemIconStyle>
                      {isActiveSub ? item.dark_icon : item.icon}
                    </ListItemIconStyle>
                    <ListItemText disableTypography primary={title} />
                  </ListItemStyle> :
                  (permissions[item.hasAccessMenu]?.read || permissions[item.hasAccessMenu]?.write) &&
                  <ListItemStyle
                    key={title}
                    component={RouterLink}
                    to={path}
                    sx={{
                      color: '#717C90',
                      "&:hover": {
                        backgroundColor: "transparent", // Set background color to transparent on hover
                        color: "#FFFFFF", // Use the inherit color on hover
                      },
                      ...(isActiveSub && activeSubStyle),
                    }}
                  >
                    <ListItemIconStyle>
                      {isActiveSub ? item.dark_icon : item.icon}
                    </ListItemIconStyle>
                    <ListItemText disableTypography primary={title} />
                  </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        color: '#717C90',
        ...(isActiveRoot && activeRootStyle),
        "&:hover": {
          backgroundColor: "transparent", // Set background color to transparent on hover
          color: "#FFFFFF", // Use the inherit color on hover
        },
      }}
    >
      <ListItemIconStyle sx={{ color: '#717C90' }}>{isActiveRoot ? dark_icon : icon}</ListItemIconStyle>

      {!showText ? <ListItemText disableTypography primary={title} /> : ''}
      {info && info}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, iscollapse, ...other }) {
  const { pathname } = useLocation();

  const [menuData, setMenuData] = useState({
    // dashboard: { read: false, write: false },
    // leagueseason: { read: false, write: false },
    // pots: { read: false, write: false },
    // user: { read: false, write: false },
    // payout: { read: false, write: false },
    // paymentsreceipts: { read: false, write: false },
    // potconfig: { read: false, write: false },
    // configuredleagues: { read: false, write: false },
    // teams: { read: false, write: false },
    // leaguematchweek: { read: false, write: false },
    // tickets: { read: false, write: false },
    // activepredictionshistory: { read: false, write: false },
    // matches: { read: false, write: false },
    // potplayers: { read: false, write: false },
    // standings: { read: false, write: false },
  });

  const match = (path) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return JSON.parse(parts.pop().split(';').shift());
    return null;
  };

  useEffect(() => {
    const storedMenuData = getCookie('menuData');
    if (storedMenuData) {
      setMenuData(storedMenuData);
    }
  }, []);
  return (
    <Box {...other} >
      <List disablePadding sx={{ p: 1, color: '#717C90' }}>
        {navConfig?.map((item) =>
          getCookie('is_superuser') ?
            <NavItem key={item.title} item={item} active={match} showText={iscollapse} permissions={menuData} /> :
            menuData[item.hasAccessMenu]?.read || menuData[item.hasAccessMenu]?.write ?
              <>
                <NavItem key={item.title} item={item} active={match} showText={iscollapse} permissions={menuData} />
              </>
              : ''
        )}
      </List>
    </Box>
  );
}
